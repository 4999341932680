<app-alert></app-alert>
<div class="dashboard">


    <div class=" d-flex justify-content-center editar-perfil " >
    <div class="recibir-notificaciones editar-perfil-form" style="text-align: left;">
        <nav>
            <a [routerLink]="[]"
            [ngClass]="stateAcordion?'link-open-accordeon active':'link-open-accordeon'"

            ><i class="icon-hide ico-email"></i><div class="text-notificaciones text-color-blue-primary">Notificaciones Correo Electrónico</div> </a>

            <!-- <div [ngClass] ="stateAcordion?'accordeon-notificaciones hide-element-top-bottom active':'accordeon-notificaciones hide-element-top-bottom'"> -->
                <div class="accordeon-notificaciones hide-element-top-bottom active">
            <ul>
                <li>

                    Programación solicitada
                    <div class="switch-container"  (click)="notifiCaciones.ProgramacionSolicitada=!notifiCaciones.ProgramacionSolicitada; actualizarNotificacionesUsuario()">
                        <input id="switch-1" type="checkbox" checked="" class="checkbox-switch">
                        <div class="switch">
                            <div [ngClass] ="notifiCaciones.ProgramacionSolicitada?'right-switch':'left-switch'"></div>
                        </div>
                    </div>
                </li>
                <li>

                   Pedido en Ruta
                    <div class="switch-container" (click)="notifiCaciones.PedidosRuta=!notifiCaciones.PedidosRuta; actualizarNotificacionesUsuario()">
                        <input id="switch-1" type="checkbox" checked="" class="checkbox-switch">
                        <div class="switch">
                            <div [ngClass] ="notifiCaciones.PedidosRuta?'right-switch':'left-switch'"></div>
                        </div>
                    </div>
                </li>
                <li>

                   Resumen Diario
                    <div class="switch-container" (click)="notifiCaciones.ResumenDiario=!notifiCaciones.ResumenDiario; actualizarNotificacionesUsuario()">
                        <input type="checkbox" checked="" class="checkbox-switch">
                        <div class="switch">
                            <div [ngClass] ="notifiCaciones.ResumenDiario?'right-switch':'left-switch'"></div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>

        <a [routerLink]="[]"
        [ngClass]="stateAcordion?'link-open-accordeon active':'link-open-accordeon'" class="mt-3"

        ><i class="icon-hide ico-sms"></i><div class="text-notificaciones text-color-blue-primary">Notificaciones SMS</div> </a>

        <!-- <div [ngClass] ="stateAcordion?'accordeon-notificaciones hide-element-top-bottom active':'accordeon-notificaciones hide-element-top-bottom'"> -->
            <div class="accordeon-notificaciones hide-element-top-bottom active">
            <ul>
            <li>

              Pedido en ruta

                    <div class="switch-container"  (click)="notifiCaciones.notificacionText=!notifiCaciones.notificacionText; actualizarNotificacionesUsuario()">
                        <input id="switch-2" type="checkbox" class="checkbox-switch">
                        <div class="switch">
                            <div [ngClass] ="notifiCaciones.notificacionText?'right-switch':'left-switch'"></div>
                        </div>
                    </div>

            </li>
        </ul>
        </div>
            <!-- <ul>
                <li>
                    <i class="icon-hide ico-sms"></i>
                    <div class="text-notificaciones">
                        Notificaciones SMS
                    </div>
                    <div class="switch-container"  (click)="notifiCaciones.notificacionText=!notifiCaciones.notificacionText; actualizarNotificacionesUsuario()">
                        <input id="switch-2" type="checkbox" class="checkbox-switch">
                        <div class="switch">
                            <div [ngClass] ="notifiCaciones.notificacionText?'right-switch':'left-switch'"></div>
                        </div>
                    </div>
                </li>
            </ul> -->
        </nav>
    </div>
    </div>
    <div class="buttons">
        <a href="javascript:void(0)" (click)="goBack()" class="btn btn-primary btn-cambiar-perfil mt-1  btn-l-md"><i class="icon icon-back"></i>Volver</a>
    </div>
</div>


