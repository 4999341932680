import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PerfilModel } from 'src/app/shared/models/PerfilModel';
import { environment } from 'src/environments/environment';
import { AccountService } from '../auth/account.service';

@Injectable({
  providedIn: 'root',
})
export class PerfilService {
  private endpointPerfil: string;
  constructor(
    private http: HttpClient,
    private PerfilUsuarioComponent: AccountService
  ) {
    this.endpointPerfil = environment.ResourcePolpaico + 'UpdatePerfil.aspx';
  }

  updatePerfil(perfil: PerfilModel) {
    if (perfil.Clave == '') {
      perfil.Clave = this.PerfilUsuarioComponent.userClave;
    }
    return this.http.get<string>(this.endpointPerfil, {
      headers: {
        Key: perfil.Key,
        RUT: perfil.RUT,
        NOMBRE_USUARIO: perfil.NOMBRE_USUARIO,
        MAIL_USUARIO: perfil.MAIL_USUARIO,
        FONO_USUARIO: perfil.FONO_USUARIO,
        opemail1: perfil.opemail1 ? '1' : '0',
        opemail2: perfil.opemail2 ? '1' : '0',
        opemail3: perfil.opemail3 ? '1' : '0',
        opemail4: perfil.opemail4 ? '1' : '0',
        opsms: perfil.opsms ? '1' : '0',
        firma: perfil.firma ?? '',
        Clave: perfil.Clave,
      },
    });
  }
}
