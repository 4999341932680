import * as moment from 'moment';

export class Utilidades {
  static formatNumber(num) {
    return new Intl.NumberFormat('de-DE').format(num);
  }
  static soloNumero(event) {
    let charCode = event.which ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57))
      return false;
    return true;
  }

  static groupBy = (input: any, key: string) => {
    return input.reduce((acc, currentValue) => {
      let groupKey = currentValue[key];
      if (!acc[groupKey]) {
        acc[groupKey] = [];
      }
      acc[groupKey].push(currentValue);
      return acc;
    }, []);
  };

  static getFormattedDate(fecha: string) {
    moment.updateLocale('es', {
      invalidDate: '-',
    });
    const dateToObject = moment(fecha, 'YYYY-MM-DD');
    return dateToObject.format('DD/MM/YYYY');
  }

  static getDateWithDayName(fecha: string) {
    moment.updateLocale('es', {
      invalidDate: '-',
    });
    const dateToObject = moment(fecha, 'DD/MM/YYYY');
    return dateToObject.format('dddd DD-MM-YYYY');
  }

  static getTimeAMPM(hora: string) {
    moment.updateLocale('es', {
      invalidDate: '-',
    });
    const dateToObject = moment(hora, 'HH:mm');
    return dateToObject.format('h:mm A');
  }
  static getMomentToISO_8601(fecha: moment.Moment) {
    moment.updateLocale('es', {
      invalidDate: '-',
    });
    const dateToObject = moment(fecha);
    return dateToObject.format('YYYYMMDD');
  }
  static getFormattedDate2(fecha: moment.Moment) {
    moment.updateLocale('es', {
      invalidDate: '-',
    });
    const dateToObject = moment(fecha, 'YYYY-MM-DD');
    return dateToObject.format('DD/MM/YYYY');
  }

  static getFormattedDate3(fecha: Date) {
    moment.updateLocale('es', {
      invalidDate: '-',
    });
    const dateToObject = moment(fecha, 'YYYY-MM-DD');
    return dateToObject.format('DD/MM/YYYY');
  }

  static sanitizeJSON(unsanitized: string): string {
    if (!unsanitized) return '';
    return unsanitized
      .replace(/\\/g, '')
      .replace(/\n/g, '')
      .replace(/\r/g, '')
      .replace(/\t/g, '')
      .replace(/\f/g, '')
      .replace(/"/g, '')
      .replace(/'/g, '')
      .replace(/\&/g, '');
  }

  static charLimit(event) {
    if (event.target.id === 'cantidad' && event.target.value.length >= 5)
      return false;
    if (event.target.id === 'carga' && event.target.value.length >= 3)
      return false;
    if (event.target.id === 'frecuencia' && event.target.value.length >= 3)
      return false;
    if (
      event.target.id === 'cantidadMuestras' &&
      event.target.value.length >= 3
    )
      return false;
  }

  static eliminarDuplicados(data, key): any[] {
    return [...new Map(data.map((x) => [key(x), x])).values()];
  }

  static validarKeyArray (obj, arr) {
    let aux =[obj];
    return aux.every({}.hasOwnProperty.bind(arr));
  }

  static clienteBloqueado():boolean {
    let user = JSON.parse(localStorage.getItem('user'));
    if(user.BLOQUEADO == "1"){
      return true;
    }
    else return false;
    
  }

  static replaceSpecialCharacters(str: string): string {
    const specialCharactersMap: { [key: string]: string } = {
      'á': 'a', 'é': 'e', 'í': 'i', 'ó': 'o', 'ú': 'u',
      'Á': 'A', 'É': 'E', 'Í': 'I', 'Ó': 'O', 'Ú': 'U',
      'ñ': 'n', 'Ñ': 'N', 'ü': 'u', 'Ü': 'U', '¿': '',
      '¡': '' 
    };
  
    return str.replace(/[áéíóúÁÉÍÓÚñÑüÜ¿¡ ]/g, match => specialCharactersMap[match] || match);
  }

  static removeUnwantedCharacters(inputString: string): string {
    const unwantedCharsRegex = /[&@€”)(;:\/-]/g;
    return inputString.replace(unwantedCharsRegex, ' ');
  }


}
