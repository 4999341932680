<header class="header-user" *ngIf="isAnonimo">
  <h1>Firmar Guía</h1>
</header>

<app-historial-programacion-filtros
  [lstObras]="lstObras"
  [selectObrasText]="obraSelecionada"
  [ClaseModal]="classFiltro"
  [selectObras]="codigoObraSelecionada"
  [Opcion]="false"
>
</app-historial-programacion-filtros>
<div class="dashboard dashboard-center">
  <div class="Detalle-pedido">
    <div class="box">
      <div class="content">
        <div class="text-color-label-title">
          <label class="detail-order mb-0 mb-sm-2">Detalle pedido</label>
          <div class="icon-container">
            <a (click)="toggleAccordionDet()">
              <mat-icon *ngIf="isAccordionOpenDet">expand_less</mat-icon>
              <mat-icon *ngIf="!isAccordionOpenDet">expand_more</mat-icon>
            </a>
          </div>
        </div>

        <div *ngIf="isAccordionOpenDet">
          <div class="row mt-2 mt-sm-0">
            <div class="col-md-4 col-xs-12 mb-3 mb-sm-0">
              <span class="style-span"
                >Direccion: {{ ListaPedidos[0].DIRECCION_ENTREGA_H }}
              </span>
            </div>
            <div class="col-md-4 col-xs-12 mb-3 mb-sm-0">
              <div class="label-row-gray">
                <label class="style-label-title mb-0 mb-sm-2"
                  >N de pedido:<span class="style-span-title"
                    >{{ ListaPedidos[0].NUMERO_PEDID_H }}
                  </span></label
                >
              </div>
              <div>
                <span class="style-span">{{
                  ListaPedidos[0].DESCRIPCION_PRODUCTO_H
                }}</span>
              </div>
              <div>
                <span class="style-span"
                  >Carga: {{ ListaPedidos[0].CANT_DESP_REAL_H }}
                </span>
              </div>
              <!--<div>
                                <span class="style-span">Camion: {{ ListaPedidos[0].NROCARGA_H }}/{{ ListaPedidos[0].CAMIONES_REQUERIDOS_H }} </span>
                            </div>-->
              <div>
                <span class="style-span"
                  >N de sello: {{ ListaPedidos[0].SELLO }}
                </span>
              </div>
              <div>
                <span class="style-span"
                  >Muestra: {{ ListaPedidos[0].MUESTRAS }}
                </span>
              </div>
            </div>
            <div class="col-md-4 col-xs-12 mb-3 mb-sm-0">
              <div>
                <label class="style-label-title mb-0 mb-sm-2"
                  >Fecha entrega: {{ ListaPedidos[0].FECHA_PED_H }}</label
                >
              </div>
              <div>
                <span class="style-span"
                  >Hora estimada inicial:
                  {{ getStimatedTime(ListaPedidos[0]) }}.
                </span>
              </div>
              <div>
                <span class="style-span"
                  >Frecuencia: cada {{ ListaPedidos[0].FRECUENCIA_H }} minutos.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
  </div>
  <app-alert></app-alert>

  <div class="PasoSeguimiento" *ngIf="PasoSeguimiento">
    <div class="text-color-label-title">
      <label class="subtitle">Resumen de camiones</label>
    </div>

    <div class="status-bar">
      <div class="status delivered">{{ pedidosEntregado }} m³</div>
      <div class="status in-transit">{{ pedidosTransito }} m³</div>
      <div class="status pending">{{ pedidoPendiente }} m³</div>
    </div>
    <div class="status-labels">
      <div class="label">Entregado</div>
      <div class="label">En tránsito</div>
      <div class="label">Pendiente</div>
    </div>

    <div class="total-label text-color-label-title">
      <label>Total a entregar: {{ TotalPedido }} m³</label>
    </div>

    <div class="subtitle-container">
      <div class="subtitle mt-0 mt-sm-1">Ruta del camión</div>
      <br class="d-none d-sm-block" />
    </div>

    <div class="container-fluid d-none d-sm-block">
      <div class="row">
        <div class="col-md-6 col-12">
          <div
            *ngFor="let pedido of ListaPedidos; let index = index"
            class="box"
          >
            <div class="text-color-label-title">
              <div class="col-icon-img icon-camion-gris"></div>
              {{ getOrderTruck(index) }}
              <div
                class="icon-container-estado"
                [style.color]="getStatusInfo(pedido.STATUS_H).color"
                [style.background]="getStatusInfo(pedido.STATUS_H).Backcolor"
              >
                <label>{{ getStatusInfo(pedido.STATUS_H).estado }}</label>
              </div>
              <div class="icon-container">
                <div
                  class="col-icon-img icon-seguimiento-activo"
                  (click)="mostrarMapa(pedido)"
                ></div>
                <div
                  class="col-icon-img icon-guia-firmada-activo"
                  (click)="abrirEnNuevaPestana(pedido.PDF_GUIA_H)"
                ></div>
                <a (click)="toggleAccordion(pedido)">
                  <mat-icon *ngIf="isAccordionOpen">expand_less</mat-icon>
                  <mat-icon *ngIf="!isAccordionOpen">expand_more</mat-icon>
                </a>
              </div>
            </div>
            <div class="row" *ngIf="pedido.isAccordionOpen">
              <div class="col-4">
                <div class="info">
                  <label class="text-color-label-title"
                    >Patente:
                    <span class="text-color-label-desc">{{
                      pedido.PATENTE_H
                    }}</span></label
                  >
                  <label class="text-color-label-title"
                    >Operador:
                    <span class="text-color-label-desc">{{
                      pedido.CONDUCTOR_H
                    }}</span></label
                  >
                  <label class="text-color-label-title"
                    >Camión:
                    <span class="text-color-label-desc">{{
                      pedido.DESIGNATION_H
                    }}</span></label
                  >
                </div>
              </div>
              <div class="col-4">
                <div class="info">
                  <label class="text-color-label-title"
                    >Hora estimada:
                    <span class="text-color-label-desc">{{
                      getStimatedTime(pedido)
                    }}</span></label
                  >
                </div>
              </div>
              <div class="col-4">
                <div class="info">
                  <label class="text-color-label-title"
                    >Cantidad a despachar:
                    <span class="text-color-label-desc"
                      >{{ pedido.CANT_DESP_REAL_H }} m3</span
                    ></label
                  >
                  <label class="text-color-label-title"
                    >N° de guía:
                    <span class="text-color-label-desc">{{
                      pedido.FOLIO_H
                    }}</span></label
                  >
                </div>
              </div>
            </div>
            <div
              class="actions"
              *ngIf="IsSigOrReject(pedido) == ''"
              [ngClass]="{ 'mt-4': !pedido.isAccordionOpen }"
            >
              <button
                class="btn btn-secondary"
                (click)="RechazarCamion(pedido)"
                [disabled]="pedido.ES_GUIA_DIGITAL != '1'"
              >
                Rechazar pedido
              </button>
              <button
                class="btn btn-primary-aceptar"
                (click)="AceptarPedido(pedido)"
                [disabled]="
                  !validateStatuSig(pedido.STATUS_H, pedido.ES_GUIA_DIGITAL)
                "
              >
                Aceptar pedido
              </button>
            </div>
            <div class="mensaje-container" *ngIf="IsSigOrReject(pedido) != ''">
              <p
                class="mensaje-text"
                sty
                [ngClass]="{ 'reject-text': isReject(pedido) }"
              >
                {{ IsSigOrReject(pedido) }}
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <iframe *ngIf="mostrarMapaPedido.viewMapa"
            frameBorder="0"
            [src]="safeSrc"
            height="100%"
            width="100%"
            frameBorder="0"
          ></iframe>
          <div class="image-container" *ngIf="mostrarMapaPedido.viewImg">
            <img [src]="safeSrc"  class="responsive-img" />
          </div>
          
        </div>
      </div>
    </div>

    <div class="d-xs-block d-sm-none col-xs-12 mt-1">
      <span class="show-all-trucks">Ver ubicación de todos los camiones</span>
      <div class="col-xs-12" style="height: 350px" *ngIf="mostrarMapaPedido.viewMapa">
        <iframe 
        frameBorder="0"
        [src]="safeSrc"
        height="100%"
        width="100%"
        frameBorder="0"
      ></iframe>
     
      </div>
      <div class="col-xs-12" style="height: auto" *ngIf="mostrarMapaPedido.viewImg">
        <img [src]="safeSrc"  class="responsive-img" />
      </div>
      <div class="box my-4">
        <div class="content">
          <div
            class="text-color-label-title px-2"
            (click)="toggleAccordionTrucks()"
          >
            <label class="detail-order mb-0 mb-sm-2">Camión</label>
            <div class="icon-container">
              <a>
                <mat-icon *ngIf="isAccordionOpenTrucks">expand_less</mat-icon>
                <mat-icon *ngIf="!isAccordionOpenTrucks">expand_more</mat-icon>
              </a>
            </div>
          </div>
          <div *ngIf="isAccordionOpenTrucks" class="px-2">
            <div
              *ngFor="let pedido of ListaPedidos; let i = index"
              class="py-2 px-1 my-2 truck-option"
              (click)="selectTruck(pedido)"
            >
              {{ getOrderTruck(i) }}
            </div>
          </div>
        </div>
      </div>
      <div class="box mb-4" *ngIf="selectedTruck">
        <div class="text-color-label-title">
          <div class="col-icon-img icon-camion-gris"></div>
          {{ getOrderTruck(indexTruck) }}
          <div
            class="icon-container-estado"
            [style.color]="getStatusInfo(selectedTruck.STATUS_H).color"
            [style.background]="getStatusInfo(selectedTruck.STATUS_H).Backcolor"
          >
            <label>{{ getStatusInfo(selectedTruck.STATUS_H).estado }}</label>
          </div>
          <div class="icon-container d-flex">
            <div  class="mr-4 icon-seguimiento-activo" style="width: 40px; height: 30px"
                  (click)="mostrarMapa(selectedTruck)"
             ></div>
            <div class="mr-4" style="width: 23px; height: 30px">
              <app-pedidos-pendiente-visualizarguia
                [rutaPdf]="selectedTruck.PDF_GUIA_H"
              ></app-pedidos-pendiente-visualizarguia>
            </div>
            <a (click)="toggleAccordion(selectedTruck)">
              <mat-icon *ngIf="isAccordionOpen">expand_less</mat-icon>
              <mat-icon *ngIf="!isAccordionOpen">expand_more</mat-icon>
            </a>
          </div>
        </div>
        <div class="row mt-3" *ngIf="selectedTruck.isAccordionOpen">
          <div class="col-12 d-flex justify-content-between">
            <label class="text-color-label-title"
              >Patente:
              <span class="text-color-label-desc">{{
                selectedTruck.PATENTE_H
              }}</span></label
            >

            <label class="text-color-label-title"
              >Camión:
              <span class="text-color-label-desc">{{
                selectedTruck.DESIGNATION_H
              }}</span></label
            >
          </div>
          <div class="col-12 d-flex justify-content-between">
            <div class="px-0">
              <label class="text-color-label-title mb-0">Hora estimada: </label>
              <span class="text-color-label-desc">{{
                getStimatedTime(selectedTruck)
              }}</span>
            </div>
            <div class="px-0">
              <label class="text-color-label-title mb-0"
                >Cantidad a despachar:
              </label>
              <span class="text-color-label-desc"
                >{{ selectedTruck.CANT_DESP_REAL_H }} m3</span
              >
            </div>
          </div>
          <div class="col-12 d-flex justify-content-between">
            <div>
              <label class="text-color-label-title mb-0">Operador: </label>
              <span class="text-color-label-desc">{{
                selectedTruck.CONDUCTOR_H
              }}</span>
            </div>
            <div>
              <label class="text-color-label-title mb-0">N° de guía: </label>
              <span class="text-color-label-desc">{{
                selectedTruck.FOLIO_H
              }}</span>
            </div>
          </div>
        </div>
        <div
          class="mt-2"
          *ngIf="IsSigOrReject(selectedTruck) == ''"
          [ngClass]="{ 'mt-4': !selectedTruck.isAccordionOpen }"
        >
          <button
            class="btn btn-secondary mb-3 btn-custom"
            (click)="RechazarCamion(selectedTruck)"
            [disabled]="selectedTruck.ES_GUIA_DIGITAL != '1'"
          >
            Rechazar camión
          </button>
          <button
            class="btn btn-primary-aceptar btn-custom"
            (click)="AceptarPedido(selectedTruck)"
            [disabled]="
              !validateStatuSig(
                selectedTruck.STATUS_H,
                selectedTruck.ES_GUIA_DIGITAL
              )
            "
          >
            Aceptar camión
          </button>
        </div>
        <div
          class="mensaje-container mt-2"
          *ngIf="IsSigOrReject(selectedTruck) != ''"
        >
          <p
            class="mensaje-text"
            sty
            [ngClass]="{ 'reject-text': isReject(selectedTruck) }"
          >
            {{ IsSigOrReject(selectedTruck) }}
          </p>
        </div>
      </div>
    </div>
  </div>

  <ng-template #contenidoModal>
    <div>
      <div class="header-container-modal">
        <label></label>
        <div mat-icon-button class="close-icon" (click)="onCierrenModal()">
          <mat-icon aria-label="Cerrar">close</mat-icon>
        </div>
      </div>

      <div style="text-align: center">
        <img
          src="assets/images/icons/warningmodal.svg"
          class="img-fluid style-warning"
          alt=""
          style="margin-bottom: 16px"
        />
        <h6 class="text-title-modal">Retorno de hormigón</h6>
        <p class="text-descripcion-modal">
          Registramos una devolución de {{ CantRetorno }} m3 de volumen de
          hormigón. <br />
          Esto podría generar sobrecostos.
        </p>
      </div>
      <div class="text-center mt-3">
        <button
          type="button"
          class="btn btn-primary col-8 col-sm-3 mx-auto"
          (click)="onCierrenModal()"
        >
          Confirmar
        </button>
      </div>
    </div>
  </ng-template>

  <div class="PasoRechazo" *ngIf="PasoRechazo">
    <app-rechazo-camion
      (returnButton)="RegresarSeguimiento()"
      [order]="orderSelected"
    ></app-rechazo-camion>
  </div>

  <div class="PasoComentarios" *ngIf="PasoComentarios">
    <app-historial-seguimiento-comentarios
      [pedido]="PedidoAceptar"
      (VolverSeguimientoEmitter)="RegresarSeguimiento()"
      (GuardarComentariosEmitter)="GuardarComentarios($event)"
    ></app-historial-seguimiento-comentarios>
  </div>

  <div class="PasoComentarios" *ngIf="PasoFirma">
    <app-historial-seguimiento-firma
      [pedido]="PedidoAceptar"
    ></app-historial-seguimiento-firma>
  </div>
</div>

<ng-template #modalInformation>
  <div id="modal-content" class="modal-relative">
    <span class="modal-close advertising-close" (click)="closeModal()">X</span>
    <div class="modal-content" style="text-align: center;">Esta guía ya fue firmada</div>
  </div>
</ng-template>

<ng-template #modalGuia>
  <div class="dashboard custom-dashboard" style="padding: 1rem; padding-top: 0px">
    <div class="header-container">
      <h6 class="text-title"></h6>
    </div>
    <div class="facturas text-size-sm d-flex justify-content-center">
      <div class="ancho-pdf" style="max-height: 80vh; overflow: auto;">
        <div class="products contenido-impresion">
          <div class="card-box-body border-top-1 pt-4 pb-2 border-bottom-0">
            <div class="text-center pdf-container">
              <pdf-viewer
                [src]="urlGuia"
                [zoom-scale]="'page-width'"
                [original-size]="false"
                [render-text]="true"
              ></pdf-viewer>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-3 d-md-flex justify-content-end">
      <div class="buttons mr-md-3">
        <a href="javascript:void(0)" (click)="RechazarCamion(PedidoAceptar)" class="btn btn-rechazar"
          >Rechazar</a
        >
      </div>

      <div class="buttons">
        <a
          href="javascript:void(0)"
          (click)="AceptarPedido(PedidoAceptar)"
          class="btn btn-primary btn-back btn-l-md"
          >Recibir</a
        >
      </div>
    </div>
  </div>
</ng-template>

