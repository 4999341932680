<!--login-->
<div class="form p-4">
    <app-alert></app-alert>

    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div *ngIf="enumEtapa.Login==etapa">
            <div class="form-group">
                <input type="text" class="form-input mat-input form-control" name="rut" formControlName="rut"
                    [ngClass]="{ 'is-invalid': submitted && f.rut.errors }" id="rut" placeholder="Ej: 12345678k">
                <label for="rut" class="mat-label">RUT</label>
                <div *ngIf="submitted && f.rut.errors" class="invalid-feedback pull-left">
                    <div *ngIf="f.rut.errors.required" class="pull-left">
                        El RUT es requerido
                    </div>
                </div>
                <div class="help-text">Ingresa tu Rut sin puntos ni guion</div>
            </div>

            <div class="form-group">
                <input id="password" class="form-input mat-input form-password form-control"
                    [type]="fieldTextType ? 'text' : 'password'" placeholder="Contraseña" name="password"
                    formControlName="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                <label class="mat-label" for="password">Contraseña / Número interno</label>
                <i toggle="#password-field" class="toggle-password" (click)="toggleFieldTextType()"
                    [ngClass]="{ 'fa-eye-slash': !fieldTextType, 'fa-eye': fieldTextType }"></i>
                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                    <div *ngIf="f.password.errors.required" class="pull-left">
                        La contraseña es requerida
                    </div>
                </div>
                <div class="help-text">Ingresa tu contraseña</div>
            </div>
        </div>
     
    
        <div class="form-group" class="form-group" *ngIf="etapa==enumEtapa.SelecionEmpresa">
          
            <input type="text" class="form-input mat-input form-control" 
                formControlName="rutEmp" [ngClass]="{ 'is-invalid': submitted && f.rutEmp.errors }"
                placeholder="Ej: 12345678k" (keyup)="onDisableButton()" (paste)="onDisableButton()">
            <label for="rutEmp" class="mat-label">Rut Empresa</label>
           
            <div class="help-text">Ingresa tu Rut de Empresa sin puntos ni guion</div>
            <div *ngIf="btnDisable" class="invalid-feedback pull-left">
                <div *ngIf="btnDisable" class="pull-left">
                    El Rut de la empresa es requerido
                </div>
            </div>
           
        </div>
    
       
        <div class="buttons">
            <button class="btn btn-primary" id="formButton" type="submit" [disabled]="!loginForm.valid">Ingresar</button>
        </div>
    </form>
   
    <!--<div>
        <h2>Generador de QR para contacto</h2>
        <qrcode [qrdata]="vCard" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
    </div>-->
      
      


</div>