<div [class]="'call-to-action ' + classModal">
  <div class="content lightbox-content-show" [ngClass]="{ isMixer: Mixer }">
    <div class="content-header">
      <div
        (click)="eventoCerrarModal()"
        style="cursor: pointer"
        class="icon-hide close-perfil-user icon-close"
      >
        
      </div>
    </div>
    <nav class="nav-perfil-users">
      <ul>
        <li *ngIf="false">
          <a [routerLink]="['/notificaciones']" (click)="eventoCerrarModal()"
            ><i class="icon icon-notificaciones"></i>Notificaciones<i
              class="icon angle-right"
            ></i
          ></a>
        </li>
        <li >
          <a (click)="toggleEstadoAcordeon()" style="cursor: pointer">
            <i class="icon icon-notificaciones"></i>Estado
            <i
              [ngClass]="{
                'icon icon-open-tab': isEstadoAcordeonOpen,
                'icon angle-right': !isEstadoAcordeonOpen
              }"
            ></i>
          </a>
          <div *ngIf="isEstadoAcordeonOpen" class="estado-acordeon-content">
            <div class="estado" (click)="setStatus(1)">
              <div class="circulo activo"></div>
              Activo
            </div>
            <div class="estado" (click)="setStatus(2)">
              <div class="circulo inactivo"></div>
              Ausente
            </div>
            <div class="estado" (click)="setStatus(0)">
              <div class="circulo falla"></div>
              Desconectado
            </div>
          </div>
        </li>
        <li>
          <a
            [routerLink]="[]"
            (click)="openModalCerrarSession()"
            class="cerrar-sesion"
            ><i class="icon icon-cerrar-sesion"></i>Cerrar Sesión<i
              class="icon angle-right"
            ></i
          ></a>
        </li>
      </ul>
    </nav>
  </div>
</div>
<app-modal-confirmar-cerrar-session
  [classModal]="classModalCerrarSession"
  (enventoCerrarModalPadre)="cerrarModalSession()"
  (enventoCerrarSessionPadre)="cerrarSession()"
></app-modal-confirmar-cerrar-session>
