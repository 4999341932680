import { Injectable } from '@angular/core';
import { EnumRoles } from 'src/app/shared/Enumeradores/enumRoles.enum';
import { AccountService } from './account.service';

@Injectable({
  providedIn: 'root'
})
export class RolesUserService {

  constructor(
    private accountService: AccountService) { }

  onValidarMenu(elemento: string) {
    if (this.accountService.userValue.ADM == 0 ) {
      return this.getPermisologiaMixer(elemento);
    }
  }
  
  private getPermisologiaMixer(elemento: string) {
    switch (elemento) {
      case (EnumRoles.historialprogramacion):
        {
          return false;
        }

      default:
        {
          return true;
        }

    }

  }

}
