import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { FacturasFilterModel } from 'src/app/shared/models/FacturasFilterModel';
import { FiltroHistorialPedidoModel } from 'src/app/shared/models/FiltroHistorialPedidoModel';

@Injectable()
export class MenuService {
  public tilteMenu: string;
  public btnPrevius: boolean = true;
  public productoSelecionado: string;
  public codigoproductoSelecionado: string;
  public paginaPrevius: string;
  public filterFields: any | FiltroHistorialPedidoModel;
  public facturafiltereds: FacturasFilterModel;
  public globalObraCodFilter: string;
  public globalObraNameFilter: string;
  public showModalFirstTime: boolean = true;
  public return: Subject<any>;

  constructor() {
    this.return = new Subject();
  }
}
