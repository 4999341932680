<div
  class="container-filtros"
  style="position: relative; top: 0px; z-index: 1; padding: 1rem"
>
  <div class="col-selected">
    <strong class="text-color-blue-primary label-title">Total pedidos: </strong>
    <strong> {{ numberOrdersFiltered }}</strong>
  </div>
  <div class="col-action">
    <div (click)="onOpenModal()" style="cursor: pointer" class="link-filtros">
      <i class="icon-hide filter"></i><strong>Filtros</strong>
    </div>
  </div>
</div>

<ng-template #contenidoModal>
  <div [class]="'filtros-busqueda' + classModal">
    <div class="content lightbox-content-show pb-2">
      <!--filtros-->
      <div class="filtros-info">
        <div class="header-container">
          <h6 class="text-title">Filtros</h6>
          <button mat-icon-button class="close-icon" (click)="onCierrenModal()">
            <mat-icon aria-label="Cerrar">close</mat-icon>
          </button>
        </div>

        <p class="text-descripcion">
          Aplica los filtros que encuentres necesarios para visualizar tus
          datos.
        </p>

        <div [formGroup]="formEdicion">
          <div class="form-group">
            <input
              type="text"
              formControlName="numeroBuscar"
              class="form-input mat-input"
              name="inputBuscar"
              id="inputBuscar"
              placeholder="Escribe tu búsqueda"
            />
            <label for="inputBuscar" class="mat-label"
              >Escribe tu búsqueda</label
            >
            <button type="button" class="btn-search">
              <i class="search"></i>
            </button>
            <div class="messages"></div>
          </div>

          <div>
            <div
              class="form-group"
              (focus)="picker.open()"
              (click)="picker.open()"
            >
              <mat-form-field appearance="fill" style="width: 100% !important">
                <mat-label>Selecciona un rango de fechas</mat-label>
                <mat-date-range-input
                  [formGroup]="range"
                  [rangePicker]="picker"
                  [max]="fechaMaxima"
                  [min]="fechaMinima"
                >
                  <input
                    matStartDate
                    formControlName="start"
                    placeholder="Fecha desde"
                  />
                  <input
                    matEndDate
                    formControlName="end"
                    placeholder="Fecha hasta"
                  />
                </mat-date-range-input>
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker-toggle matSuffix (click)="clearDate($event)">
                  <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                </mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>

                <mat-error
                  *ngIf="range.controls.start.hasError('matStartDateInvalid')"
                  >La fecha inicio no es válida</mat-error
                >
                <mat-error
                  *ngIf="range.controls.end.hasError('matEndDateInvalid')"
                  >La fecha fin no es válida</mat-error
                >
              </mat-form-field>
            </div>
          </div>

          <div class="cal d-none"></div>

          <div class="filter-container" *ngIf="isStatusFilterEnabled">
            <h6 class="text-title">Filtrar por estado</h6>
            <div class="row filters">
              <div
                class="col-programacion col-confirmado"
                (click)="selectStatus(0)"
              >
                <div
                  style="cursor: pointer"
                  [ngClass]="{
                    filtros: true,
                    selected: statusSelected == 0,
                    todos: statusSelected == 0
                  }"
                >
                  <span class="point todos"></span>Todos ({{ numberOrders }})
                </div>
              </div>
              <div
                class="col-programacion col-confirmado"
                (click)="selectStatus(1)"
              >
                <div
                  style="cursor: pointer"
                  [ngClass]="{
                    filtros: true,
                    selected: statusSelected == 1,
                    entregados: statusSelected == 1
                  }"
                >
                  <span class="point entregados"></span>Entregado ({{
                    numberOrdersDelivered
                  }})
                </div>
              </div>
              <!--end col-->
              <div
                class="col-programacion col-programado"
                (click)="selectStatus(2)"
              >
                <div
                  style="cursor: pointer"
                  [ngClass]="{
                    filtros: true,
                    selected: statusSelected == 2,
                    rechazados: statusSelected == 2
                  }"
                >
                  <span class="point rechazados"></span>Rechazado ({{
                    numberRejectedOrders
                  }})
                </div>
              </div>
              <div
                class="col-programacion col-programado"
                (click)="selectStatus(3)"
              >
                <div
                  style="cursor: pointer"
                  [ngClass]="{
                    filtros: true,
                    selected: statusSelected == 3,
                    pendientes: statusSelected == 3
                  }"
                >
                  <span class="point pendientes"></span>Pendiente ({{
                    numberPendingOrders
                  }})
                </div>
              </div>
            </div>
          </div>

          <div class="buttons">
            <button
              type="button"
              class="btn btn-primary btn-filtrar"
              (click)="onFiltrar()"
            >
              Buscar
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--end div filtros-->
  </div>
</ng-template>
