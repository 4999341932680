<div class="dashboard dashboard-center" style="padding: 1rem; padding-top: 0px">
  <app-pedidos-pendiente-filtro
    [dataObras]=""
    [selectObra]="obraSeleciona"
    (eventoFiltrar)="onFiltrar($event)"
    [selectCodigoObra]="codigobraSeleciona"
    [fechaMaxima]="fechaMaxima"
    [fechaMinima]="fechaMinima"
    [numberOrders]="ordersOriginal.length"
    [numberOrdersFiltered]="orders.length"
    [numberOrdersDelivered]="numberOrdersDelivered"
    [numberRejectedOrders]="numberOrdersRejected"
    [numberPendingOrders]="numberOrdersPending"
    [isStatusFilterEnabled]="true"
  >
  </app-pedidos-pendiente-filtro>
  <app-alert></app-alert>
  <div class="d-md-flex flex-md-wrap justify-content-md-around">
    <div *ngFor="let order of orders" class="container mt-md-4">
      <div class="box">
        <div class="header-card" *ngIf="isDelivered(order)">
          <button class="green-button">Entregado</button>
        </div>
        <div class="header-card" *ngIf="isRejected(order)">
          <button class="red-button">Rechazado</button>
        </div>
        <div class="header-card" *ngIf="isPending(order)">
          <button class="pending-button">Pendiente</button>
        </div>
        <div class="content">
          <div class="label-row">
            <label class="style-label"
              >Fecha y hora entregada:
              <span class="style-span"
                >{{ order.orderDate }}, {{ toAmOrPm(order.startTime) }}</span
              ></label
            >
          </div>
          <div class="label-row">
            <label class="style-label"
              >{{order.neg=='3100' ? 'Destinatario:' :  'Obra:'}} <span class="style-span">{{ order.workName }}</span></label
            >
          </div>
          <div class="label-row">
            <label class="style-label"
              >Dirección:
              <span class="style-span">{{ order.deliveryAddress }}</span></label
            >
          </div>
          <div class="label-row" *ngIf="((order.neg =='3400' || order.neg =='3100') && order.isOpen)">
            <label class="style-label"
              >Instrucción de guía:
              <span class="style-span"> {{ order.SummaryOrder }} </span></label
            >
          </div>
          <div class="label-row" *ngIf="order.neg != '3400' && order.neg != '3100'">
            <label class="style-label"
              >Observaciones(aditivos):
              <span class="style-span">{{ order.additiveRemarks }}</span></label
            >
          </div>
          <div class="label-row" *ngIf="order.neg != '3400' && order.neg != '3100'">
            <label class="style-label"
              >Camión:
              <span class="style-span"
                >{{ order.currentTripNumber }}/{{ order.loadAmount }}
              </span></label
            >
          </div>
          <div class="label-row" *ngIf="order.neg != '3400' && order.neg != '3100'">
            <label class="style-label"
              >Camión anterior:
              <span class="style-span">{{ order.truckNumber }} </span></label
            >
          </div>
          
          <div
            class="label-row"
            *ngIf="!order.isOpen && order.rejectionFlag != ''"
          >
            <label class="style-label"
              >Observaciones rechazo:
              <span class="style-span red-label"
                >{{ order.rejectionFlag }}
              </span></label
            >
          </div>
          <div class="dashed-line" *ngIf="order.isOpen"></div>
          <div class="label-row" *ngIf="order.isOpen">
            <label class="style-label"
              >Producto:
              <span class="style-span"
                >{{ order.productDescription }}
              </span></label
            >
          </div>
          <div class="label-row" *ngIf="order.isOpen">
            <label class="style-label"
              >Cantidad a despachar:
              <span class="style-span"
                >{{ order.actualQuantityShipped }} {{order.um}}
              </span></label
            >
          </div>
          <div class="label-row" *ngIf="order.isOpen && order.neg != '3400' && order.neg != '3100'">
            <label class="style-label"
              >Cantidad total pedido:
              <span class="style-span"
                >{{ order.scheduledAmount }}
              </span></label
            >
          </div>
          <div class="label-row" *ngIf="order.isOpen && order.neg != '3400' && order.neg != '3100'">
            <label class="style-label"
              >Cono: <span class="style-span">{{ order.cone }} </span></label
            >
          </div>
          <div
            class="label-row"
            *ngIf="order.isOpen && order.rejectionFlag != ''"
          >
            <label class="style-label"
              >Observaciones rechazo:
              <span class="style-span red-label"
                >{{ order.rejectionFlag }}
              </span></label
            >
          </div>
          <div *ngIf="order.isOpen && order.rejectionFlag == ''">
            <div class="dashed-line" *ngIf="order.isOpen"></div>
            <div class="label-row" *ngIf="order.isOpen && order.neg != '3400' && order.neg !='3100'">
              <label class="style-label"
                >Adición de agua:
                <span class="style-span">{{
                  order.waterAddition ? "Sí" : "No"
                }}</span></label
              >
              <label class="style-label"
                >Otra adición:
                <span class="style-span"
                  >{{ order.anotherAddition ? "Sí" : "No" }}
                </span></label
              >
            </div>
            <div class="label-row" *ngIf="order.isOpen && order.neg != '3400'">
              <label class="style-label"
                >{{ order.neg =='3100' ? "Devolución de sacos" : "Retorno hormigón:" }} 
                <span class="style-span">
                  {{ order.m3Returned }} m3</span
                ></label
              >
            </div>
            <div class="label-row" *ngIf="order.isOpen">
              <label class="style-label"
                >{{ order.neg =='3100' ? "Motivo de devolución:" : "Motivo de retorno:" }}
                <span class="style-span">
                  {{ order.reasonToReturn }}</span
                ></label
              >
            </div>
            <div class="label-row" *ngIf="order.isOpen && order.neg == '3100'">
              <label class="style-label"
                >Devolución de pallets:
                <span class="style-span">
                  {{ order.pallet }}</span
                ></label
              >
            </div>
            <div class="label-row" *ngIf="order.isOpen">
              <label class="style-label"
                >Observaciones guía:
                <span class="style-span"
                  >{{ order.guidingRemarks }}
                </span></label
              >
            </div>
            <div class="label-row" *ngIf="order.isOpen && !order.isSignature && order.isDigitalSignature">
              <label class="style-label">
                <span class="style-span red-label"
                  >Guía no firmada
                </span></label
              >
            </div>
          </div>
          <div class="dashed-line" *ngIf="order.isOpen"></div>
          <div class="label-row" *ngIf="order.isOpen && order.neg !='3100'">
            <label class="style-label"
              > {{order.neg=='3400' ? 'Numero de transporte:' :  'N° de sello:'}}
              <span class="style-span">{{ order.sealNumber }} </span></label
            >
          </div>
          <div class="label-row" >
            <label class="style-label"
              >N° de guía:
              <span class="style-span">{{ order.folioNumberGD }} </span></label
            >
          </div>
          <div class="label-row" *ngIf="order.isOpen">
            <label class="style-label"
              >{{order.neg=='3400' ? 'Entrega:' : order.neg=='3100' ? 'N° de entrega:' :  'N° de pedido:'}}
              <span class="style-span">{{ order.orderNumber }} </span></label
            >
          </div>

          <button
            mat-button
            (click)="order.isOpen = !order.isOpen"
            class="custom-button"
          >
            <span class="button-label" *ngIf="!order.isOpen">{{
              TextoInfo
            }}</span>
            <span class="button-label" *ngIf="order.isOpen">
              Ver menos información</span
            >
            <mat-icon *ngIf="order.isOpen" style="color: #007bff"
              >expand_less</mat-icon
            >
            <mat-icon *ngIf="!order.isOpen" style="color: #007bff"
              >expand_more</mat-icon
            >
          </button>
          <div class="bottom-row">
            <div class="col-12">
              <div class="row ">
                <div class="col-2" style="display: contents">
                  <app-pedidos-pendiente-visualizarguia
                    [rutaPdf]="order.routePdfGuide"
                  ></app-pedidos-pendiente-visualizarguia>
                </div>
                <div class="col-1"></div>
                <div class="col-2" style="display: contents">
                  <app-visualizar-imagenes-pedidos
                    [order]="order"
                  ></app-visualizar-imagenes-pedidos>
                </div>
                <div class="col-1"></div>
                <div class="col-2" style="display: contents" (click)="ConfirmarModificacion(order)" *ngIf="!order.isSignature && order.rejectionFlag ==''">
                    <div class="col-icon-img">
                      <img
                        src="assets/images/icons/three-point.svg"
                        class="img-fluid"
                        alt="modificar"
                        style="cursor:pointer;"
                        title="Modificar"
                      />
                    </div>
                </div>
                <div class="col-1"></div>
                <div class="col-2" style="display: contents" (click)="GenerarQR(order)" *ngIf="order.rejectionFlag ==''  && (order.isDigitalSignature && !order.isSignature) || (order.status<6 && !order.isSignature)">
                    <div class="col-icon-img">
                      <img
                        src="assets/images/icons/icon_modificar_activo.svg"
                        class="img-fluid"
                        alt="modificar"
                        style="cursor:pointer;"
                        title="Generar QR"
                      />
                    </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<ng-template #modalModificar>
  <div class="dashboard custom-dashboard" style="padding: 1rem; padding-top: 0px">
    <div class="header-container">
      <h6 class="text-title">{{titleModalUpdate}}</h6>
    </div>
    <div class="mt-3 d-md-flex justify-content-center button-container-modal" >
      <div class="buttons sizeButom " >
        <a
         href="javascript:void(0)"
        (click)="closeModal()"
        class="btn btn-back btn-back btn-l-md btnRechazar"
        >{{!isConfirUpdate ? 'Cancelar' : 'Aceptar'}}</a
        >
      </div>
      
      <div class="buttons sizeButom" *ngIf="!isConfirUpdate">
        <a
         href="javascript:void(0)"
        (click)="ModificarPedido()"
        class="btn btn-primary btn-back btn-l-md"
        >Aceptar</a
        >
      </div>
    </div>
  </div>
</ng-template>
