import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { AccountService } from 'src/app/services/auth';
import { MenuService } from 'src/app/services/menu/menu-service';
import { OrderService } from 'src/app/services/pedidos/pedidos.service';
import { Order } from 'src/app/shared/models/orders';

@Component({
  selector: 'app-pedido-pendiente',
  templateUrl: './pedido-pendiente.component.html',
  styleUrls: ['./pedido-pendiente.component.css'],
})
export class PedidoPendienteComponent implements OnInit {
  TextoInfo: string = 'Ver más información';
  fechaMinima: Date;
  fechaMaxima: Date;
  obraSeleciona: string = '';
  codigobraSeleciona: string = '';
  orders: Order[] = [];
  ordersOriginal: Order[] = [];

  @ViewChild('miDialogo') miDialogo: any;
  dialogOpacity: number = 0;
  constructor(
    private menu: MenuService,
    private readonly orderService: OrderService,
    private accountService: AccountService,
  ) {
    menu.btnPrevius = true;
    menu.tilteMenu = 'Pedidos por despachar';
  }

  isAccordionOpen = false;

  toggleAccordion() {
    this.isAccordionOpen = !this.isAccordionOpen;
    if (this.isAccordionOpen) {
      this.TextoInfo = 'Ver menos información';
    } else {
      this.TextoInfo = 'Ver más información';
    }
  }

  ngOnInit() {
    this.orderService.getAllOrders().subscribe((data) => {
      this.ordersOriginal = data;
      this.orders = data;
      let orderWithFlagPending=this.ordersOriginal.filter(x=>x.flag_pending =="1");

      this.ordersOriginal = this.ordersOriginal.filter(
        (order) =>
          order.status < 6 &&
          order.status != 0 &&
          order.rejectionFlag == '' &&
          !order.isSignature &&
          !order.isNotSigned &&
          moment(order.orderDate, 'DD/MM/YYYY').isAfter(
            moment().subtract(1, 'days')
          )
      );
      this.ordersOriginal.push(...orderWithFlagPending);
      this.orders = this.ordersOriginal;
    });
  }

  onFiltrar(event) {
    this.orders = this.ordersOriginal.filter(
      (order) =>
        (order.workName
          .toLowerCase()
          .includes(event.numeroFiltar.toLowerCase()) ||
          order.orderNumber.toString().includes(event.numeroFiltar)) &&
        (event.fechaDesde == null ||
          !moment(event.fechaHasta).isBefore(
            moment(order.orderDate, 'DD/MM/YYYY')
          )) &&
        (event.fechaHasta == null ||
          !moment(event.fechaDesde).isAfter(
            moment(order.orderDate, 'DD/MM/YYYY')
          ))
    );
  }

  onOpenModalFiltro() {
    this.dialogOpacity = 1;
    this.miDialogo.nativeElement.showModal();
  }

  cerrarDialogo() {
    this.dialogOpacity = 0;
    this.miDialogo.nativeElement.close();
  }

  getOrderString(order: Order) {
    return JSON.stringify(order);
  }

  toAmOrPm(time: string) {
    if (time == null || time == '') return 'No disponible';
    const timeArray = time.split(':');
    const hour = parseInt(timeArray[0]);
    const minute = timeArray[1];
    if (hour >= 12) {
      if (hour > 12) {
        return `${hour - 12}:${minute} PM`;
      }
      return `${hour}:${minute} PM`;
    } else {
      return `${hour}:${minute} AM`;
    }
  }

  isActive() {
    return this.accountService.userValue.ESTADO == '1';
  }
}
