import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { AccountService } from 'src/app/services/auth';
import { OrderService } from 'src/app/services/pedidos/pedidos.service';
import { Order } from 'src/app/shared/models/orders';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pedidos-pendiente-qr',
  templateUrl: './pedidos-pendiente-qr.component.html',
  styleUrls: ['./pedidos-pendiente-qr.component.css'],
})
export class PedidosPendienteQrComponent implements OnInit {
  @ViewChild('contenidoModal') contenidoModal!: TemplateRef<any>;
  dialogRef: MatDialogRef<any>;

  @Output() toNextStep = new EventEmitter<any>();

  iconoModal: string;
  tituloModal: string;
  descripcionModal: string;
  isNotificationVisible = true;
  linkQr: string;

  PasoModal = {
    preguntaModal: false,
    ConfirmacionModal: false,
  };

  isArido=false;

  @Input() order: Order;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private orderService: OrderService,
    private accountUser:AccountService
  ) {}

  ngOnInit(): void {
    debugger
    this.linkQr = `${environment.host}Seguimiento/3?tkt=${this.convertBase64()}&key=${this.accountUser.userValue.KEY}`;
    this.isArido=this.order.neg=="3400"? true: false;
    console.log(this.linkQr)
    this.orderService
      .setUnsignedGuide(
        this.order.tkt.toString(),
        this.order.orderNumber.toString()
      )
      .subscribe((response) => {
        console.log("response", response);
      });
  }

  private convertBase64(){
    let rutTransbasportista=this.accountUser.userValue.RUT_USUARIO;
    let stringBase =btoa(rutTransbasportista.replace('-',''));
    return stringBase;
  }

  private transformDate(inputDate: string): string {
    const parsedDate = moment(inputDate, 'DD/MM/YYYY');
    const formattedDate = parsedDate.format('YYYYMMDD');

    return formattedDate;
  }

  onOpenModal() {
    this.dialogRef = this.dialog.open(this.contenidoModal, {
      maxWidth: '100%', // Puedes ajustar el ancho según tus necesidades
    });
    this.guiaNoFirmada();
  }

  guiaNoFirmada() {
    this.PasoModal.preguntaModal = true;
    this.iconoModal = 'warning';
    this.tituloModal = '¿Deseas confirmar la guía no firmada?';
    this.descripcionModal =
      'Una vez confirmada no será posible cambiar la información.';
  }

  confirmarGuiaNoFirmada() {
    this.PasoModal.preguntaModal = false;
    this.PasoModal.ConfirmacionModal = true;

    this.iconoModal = 'check_modal';
    this.tituloModal = 'Guía no firmada enviada';
    this.descripcionModal =
      'La información fue enviada al cliente y al despacho para su posterior firma.';
  }

  onCierrenModal() {
    window.history.back();
    this.dialogRef.close();
  }

  hideNotification() {
    this.isNotificationVisible = false;
  }

  withoutInternet() {
    this.toNextStep.emit();
  }

  goBack() {
    this.router.navigate(['/pedidospendiente']);
  }
}
