import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CanDeactivate, } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
/*import { ModalExitFormComponent } from 'src/app/components/nueva-programacion/nueva-programacion-form/modal-exit-form/modal-exit-form.component';*/
import { ComponentCanDeactivate } from './component-can-deactivate';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateGuard implements CanDeactivate<ComponentCanDeactivate>{
  constructor(private modalService: MatDialog) {
  }
  canDeactivate(component: ComponentCanDeactivate): Observable<boolean> {
    if (component.canDeactivate()) {
      return of(true);
    }

    /*const dialogRef = this.modalService.open(ModalExitFormComponent, {
      width: '600px',
      height: '250px',
    });
    return dialogRef.afterClosed().pipe(
      map(result => result === true)
    );*/
  }

}
