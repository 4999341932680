import { ScrollStrategyOptions } from '@angular/cdk/overlay';
import {
  Component,
  ElementRef,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { OrderService } from 'src/app/services/pedidos/pedidos.service';
import { Order } from 'src/app/shared/models/orders';

@Component({
  selector: 'app-pedidos-pendiente-firma',
  templateUrl: './pedidos-pendiente-firma.component.html',
  styleUrls: ['./pedidos-pendiente-firma.component.css'],
})
export class PedidosPendienteFirmaComponent implements OnInit {
  @Input() order: Order;
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('fileInputCamera') fileInputCamera: ElementRef;
  @ViewChild('contenidoModal') contenidoModal!: TemplateRef<any>;
  dialogRef: MatDialogRef<any>;
  isChecked: boolean = false;
  emailPattern = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$";
  data: any = {
    name: '',
    rut: '',
    signature: '',
    mail:'',
    dvrut:''
  };
  isMobile: boolean;
  isTablet: boolean;

  @ViewChild('signatureCanvas') signatureCanvas: ElementRef<HTMLCanvasElement>;
  private context: CanvasRenderingContext2D;
  private drawing = false;

  constructor(
    private dialog: MatDialog,
    private deviceService: DeviceDetectorService,
    private orderService: OrderService,
    private scrollStrategyOptions: ScrollStrategyOptions
  ) {}

  ngOnInit(): void {
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
  }

  onOpenModal() {
    this.dialogRef = this.dialog.open(this.contenidoModal, {
      maxWidth: '100%', // Puedes ajustar el ancho según tus necesidades
      scrollStrategy: this.scrollStrategyOptions.noop(),
    });
  }

  onCierrenModal() {
    this.dialogRef.close();
  }

  recargarFirma() {
    // Lógica para recargar la firma
  }

  adjuntarImagen() {
    // Lógica para adjuntar una imagen
  }

  tomarFoto() {
    // Lógica para tomar una foto
  }

  ngAfterViewInit() {
    this.context = this.signatureCanvas.nativeElement.getContext('2d');
    if (this.isMobile) {
      this.context.canvas.width = 300;
      this.context.canvas.height = 150;
    }
    if (this.isMobile || this.isTablet) this.touchStart();
  }

  startDrawing(event: MouseEvent) {
    this.drawing = true;
    this.context.beginPath();
    this.context.moveTo(this.getMouseX(event), this.getMouseY(event));
  }

  draw(event: MouseEvent) {
    if (!this.drawing) return;
    this.context.lineTo(this.getMouseX(event), this.getMouseY(event));
    this.context.stroke();
  }

  stopDrawing() {
    this.drawing = false;
    this.saveSignature();
  }

  clearSignature() {
    this.context.clearRect(
      0,
      0,
      this.signatureCanvas.nativeElement.width,
      this.signatureCanvas.nativeElement.height
    );
    this.data.signature = ''
  }

  private getMouseX(event: MouseEvent): number {
    return (
      event.clientX -
      this.signatureCanvas.nativeElement.getBoundingClientRect().left
    );
  }

  private getMouseY(event: MouseEvent): number {
    return (
      event.clientY -
      this.signatureCanvas.nativeElement.getBoundingClientRect().top
    );
  }

  touchStart() {
    this.signatureCanvas.nativeElement.ontouchstart = (event) => {
      event.preventDefault();
      this.drawing = true;
      this.context.beginPath();
      this.context.moveTo(this.getTouchX(event), this.getTouchY(event));
    };
    this.signatureCanvas.nativeElement.ontouchmove = (event) => {
      event.preventDefault();
      if (!this.drawing) return;
      this.context.lineTo(this.getTouchX(event), this.getTouchY(event));
      this.context.stroke();
    };
    this.signatureCanvas.nativeElement.ontouchend = (event) => {
      event.preventDefault();
      this.drawing = false;
      this.data.signature = this.getSignatureAsBase64();
    };
  }

  getTouchX(event: TouchEvent): number {
    return (
      event.touches[0].clientX -
      this.signatureCanvas.nativeElement.getBoundingClientRect().left
    );
  }

  getTouchY(event: TouchEvent): number {
    return (
      event.touches[0].clientY -
      this.signatureCanvas.nativeElement.getBoundingClientRect().top -
      10
    );
  }

  getSignatureAsBase64(): string {
    return this.signatureCanvas.nativeElement.toDataURL('image/png');
  }

  saveSignature() {
    this.data.signature = this.getSignatureAsBase64();
  }

  saveData() {
    debugger
    this.data.signature = this.getSignatureAsBase64();
    this.data.rut +=this.data.dvrut;
    this.orderService
      .setSignedGuide(
        this.order.tkt.toString(),
        this.order.orderNumber.toString(),
        this.data.rut,
        this.data.name,
        this.data.signature
      )
      .subscribe((res) => {
        this.onOpenModal();
      });
  }

  goBack() {
    window.history.back();
    this.onCierrenModal();
  }

  openFileSelector() {
    this.fileInput.nativeElement.click();
  }

  onFileSelected(event: any) {
    const files: FileList = event.target.files;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.data.signature = e.target.result;
        this.drawImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }

    this.fileInput.nativeElement.value = '';
    if (this.isMobile) this.fileInputCamera.nativeElement.value = '';
  }

  openFileSelectorCamera() {
    this.fileInputCamera.nativeElement.click();
  }

  drawImage(preview: string) {
    this.clearSignature();
    this.context.clearRect(
      0,
      0,
      this.signatureCanvas.nativeElement.width,
      this.signatureCanvas.nativeElement.height
    );
    const image = new Image();
    image.onload = () => {
      this.context.drawImage(image, 0, 0);
    };
    image.onload = () => {
      const ratio = Math.min(
        this.context.canvas.width / image.width,
        this.context.canvas.height / image.height
      );
      const x = (this.context.canvas.width - image.width * ratio) / 2;
      const y = (this.context.canvas.height - image.height * ratio) / 2;
      const width = image.width * ratio;
      const height = image.height * ratio;

      this.context.drawImage(image, x, y, width, height);
    };
    image.src = preview;
  }

  isDisabled() {
    debugger
    const hasTwoConsecutiveKs = /[kK]{2}/.test(this.data.rut + this.data.dvrut); // Verifica si hay dos 'k' o 'K' consecutivas
    const isEmailInvalid = this.data.mail && !new RegExp(this.emailPattern).test(this.data.mail);
    return (
      this.data.name === '' ||
      this.data.rut === '' ||  this.data.dvrut === '' ||
      !((this.data.rut.length +  this.data.dvrut.length === 8 )|| (this.data.rut.length + this.data.dvrut.length=== 9)) || 
      hasTwoConsecutiveKs || // Deshabilita si hay dos 'k' consecutivas
      this.data.signature == '' ||
      isEmailInvalid 
    );
  }
  
  

  onKeyDown(event: KeyboardEvent) {
    const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'k', 'K', 'Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'];
    const inputValue = (event.target as HTMLInputElement).value;
  
    if (!allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  
    // Evitar que el usuario ingrese más de 9 caracteres
    if (inputValue.length >= 8 && !['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'].includes(event.key)) {
      event.preventDefault();
    }
  }
}
