import { EventEmitter } from '@angular/core';
import { Input } from '@angular/core';
import { Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-perfil-usuario-change-ok',
  templateUrl: './perfil-usuario-change-ok.component.html'
})
export class PerfilUsuarioChangeOkComponent implements OnInit {
  @Output() eventoPadreCambiarEtapa= new EventEmitter<number>();
  @Input() numberEtapas:any;
  constructor() { }

  ngOnInit(): void {
  }
  onChangeDetalle()
  {
    this.eventoPadreCambiarEtapa.emit(this.numberEtapas.Detalles);
  }
}
