<div class="dashboard">
    <div class="d-flex justify-content-center editar-perfil">
    <form [formGroup]="formEdicion" (ngSubmit)="onSubmit()" class="editar-perfil-form" >
        <div class="header-page text-center pt-3 pb-3">
            <h2>¿Necesitas cambiar tú contraseña?</h2>
            <p>Sigue las instrucciones para poder generar el cambio de tu contraseña</p>
        </div>

        <div class="form-group">
            <input 
            formControlName="password"
            [class]="'form-input mat-input form-password '"  
            [type]="showPasswor?'text':'password' "  placeholder="Ingresar nueva contraseña"
            maxlength=16
         
            (keyup)="onValidateErrorClass()"
            >
            <label class="mat-label" for="password">Password</label>
            <i [class]="showPasswor?'fa fa-eye':'fa fa-eye-slash'" 
            (click)="showPasswor= !showPasswor" ></i>
            <div class="messages d-block p-3" style="text-align: left;">
                <div [ngClass]="erroModal.longitud?'positive':'negative'">Mínimo 8 caracteres</div>
                <div [ngClass]="erroModal.letraMayuscula?'positive':'negative'">1 Mayúscula mínimo</div>	
                <div [ngClass]="erroModal.letraMinuscula?'positive':'negative'">1 Letra mínimo</div>	
                <div [ngClass]="erroModal.simbolo?'positive':'negative'">1 Símbolo</div>	
            </div>
        </div>
    
        <div class="buttons button-sticky-bottom">
            <button class="btn btn-primary btn-cambiar-password" id="formButton" type="submit">Cambiar contraseña</button>
        </div>
        <div class="buttons">
            <a href="javascript:void(0)" (click)="goBack()" class="btn btn-primary btn-cambiar-perfil mt-1"><i class="icon icon-back"></i>Volver</a>
        </div>
    </form>
    </div>
</div>