import { ScrollStrategyOptions } from '@angular/cdk/overlay';
import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { saveAs } from 'file-saver';
import { DeviceDetectorService } from 'ngx-device-detector';
import { OrderService } from 'src/app/services/pedidos/pedidos.service';
import { Order } from 'src/app/shared/models/orders';

@Component({
  selector: 'app-visualizar-imagenes-pedidos',
  templateUrl: './visualizar-imagenes-pedidos.component.html',
  styleUrls: ['./visualizar-imagenes-pedidos.component.css'],
})
export class VisualizarImagenesPedidosComponent implements OnInit {
  @Input() order: Order;
  isMobile: boolean = false;

  @ViewChild('modalContent') modalContent!: TemplateRef<any>;
  dialogRef: MatDialogRef<any>;

  constructor(
    private dialog: MatDialog,
    private orderService: OrderService,
    private deviceService: DeviceDetectorService,
    private scrollStrategyOptions: ScrollStrategyOptions
  ) {}

  ngOnInit(): void {
    this.isMobile = this.deviceService.isMobile();
  }

  openModal() {
    this.orderService.getOrder(this.order.tkt.toString()).subscribe((order) => {
      this.order = order[0];
      this.dialogRef = this.dialog.open(this.modalContent, {
        maxWidth: '90%',
        scrollStrategy: this.scrollStrategyOptions.noop(),
      });
    });
  }

  closeModal() {
    this.dialogRef.close();
  }

  download() {
    if (!this.isMobile)
      this.order.files.forEach((file) => {
        fetch(file.url)
          .then((response) => response.blob())
          .then((blob) => {
            saveAs(blob, file.fileName);
          })
          .catch((error) => {
            console.error('Error on download: ', error);
          });
      });
    else
      this.order.files.forEach((file) => {
        let url = file.url;
        window.open(url, '_blank');
      });
  }
}
