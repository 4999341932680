<div class="dashboard dashboard-center" style="padding: 1rem; padding-top: 0px">
  <app-pedidos-pendiente-filtro
    [dataObras]=""
    [selectObra]="obraSeleciona"
    (eventoFiltrar)="onFiltrar($event)"
    [selectCodigoObra]="codigobraSeleciona"
    [fechaMaxima]="fechaMaxima"
    [fechaMinima]="fechaMinima"
    [numberOrdersFiltered]="orders.length"
  >
  </app-pedidos-pendiente-filtro>
  <div class="d-flex flex-wrap justify-content-around">
    <div *ngFor="let order of orders" class="container mt-md-4">
      <div class="box">
        <div class="header-card">
          <button class="Amarillo-button">Pendiente</button>
          <div class="label-title" style="flex-direction: column" *ngIf="order.neg !='3400' && order.neg !='3100'">
            <label class="style-label" style="margin-bottom: 0px"
              >Hora y boca carga:
            </label>
            <span class="style-span"
              >{{ toAmOrPm(order.hourOne) || "No disponible" }} | {{ order.loadingMouth }}</span
            >
          </div>
        </div>
        <div class="content">
          <div class="label-row">
            <label class="style-label"
              >Fecha y hora entrega:
              <span class="style-span"
                >{{ order.orderDate }}, {{ toAmOrPm(order.startTime) }}.</span
              ></label
            >
          </div>
          <div class="label-row">
            <label class="style-label"
              >{{order.neg=='3100' ? 'Destinatario' :  'Obra'}}: <span class="style-span">{{ order.workName }}</span></label
            >
          </div>
          <div class="label-row">
            <label class="style-label"
              >Dirección:
              <span class="style-span">{{ order.deliveryAddress }}</span></label
            >
          </div>
          <div class="label-row" *ngIf="((order.neg =='3400' || order.neg =='3100') && order.isOpen)">
            <label class="style-label"
              >Instrucción de guía:
              <span class="style-span"> {{ order.SummaryOrder }} </span></label
            >
          </div>
          <div class="label-row" *ngIf="order.neg !='3400' && order.neg !='3100'">
            <label class="style-label"
              >Observaciones(aditivos):
              <span class="style-span">{{ order.additiveRemarks }}</span></label
            >
          </div>
          <div class="label-row" *ngIf="order.neg !='3400' && order.neg !='3100'">
            <label class="style-label"
              >Camión:
              <span class="style-span"
                >{{ order.currentTripNumber }}/{{ order.loadAmount }}</span
              ></label
            >
          </div>
          <div class="label-row" *ngIf="order.neg !='3400' && order.neg !='3100'">
            <label class="style-label"
              >Camión anterior:
              <span class="style-span"> {{ order.truckNumber }} </span></label
            >
          </div>
          
          <div class="dashed-line" *ngIf="order.isOpen"></div>
          <div class="label-row" *ngIf="order.isOpen">
            <label class="style-label"
              >Producto:
              <span class="style-span"
                >{{ order.productDescription }}
              </span></label
            >
          </div>
          <div class="label-row" *ngIf="order.isOpen">
            <label class="style-label"
              >Cantidad a despachar:
              <span class="style-span"
                >{{ order.actualQuantityShipped }} {{order.um}}
              </span></label
            >
          </div>
          <div class="label-row" *ngIf="order.isOpen && (order.neg !='3400' && order.neg !='3100')">
            <label class="style-label"
              >Cantidad total pedido:
              <span class="style-span"
                >{{ order.scheduledAmount }}
              </span></label
            >
          </div>
          <div class="label-row" *ngIf="order.isOpen && (order.neg !='3400' && order.neg !='3100')">
            <label class="style-label"
              >Cono: <span class="style-span">{{ order.cone }} </span></label
            >
          </div>
          <div
            class="label-row"
            *ngIf="order.isOpen && order.rejectionFlag != ''"
          >
            <label class="style-label"
              >Observaciones rechazo:
              <span class="style-span red-label"
                >{{ order.rejectionFlag }}
              </span></label
            >
          </div>
          <div class="dashed-line" *ngIf="order.isOpen"></div>
          <div class="label-row" *ngIf="order.isOpen && order.neg !='3100'" >
            <label class="style-label"
              >{{order.neg=='3400' ? 'Numero de transporte:' :  'N° de sello:'}}
              <span class="style-span">{{ order.sealNumber }} </span></label
            >
          </div>
          <div class="label-row" >
            <label class="style-label"
              >N° de guía:
              <span class="style-span">{{ order.folioNumberGD }} </span></label
            >
          </div>
          <div class="label-row" *ngIf="order.isOpen">
            <label class="style-label"
              >{{order.neg=='3400' ? 'Entrega:' : order.neg=='3100'? 'N° de entrega:': 'N° de pedido:'}}
              <span class="style-span">{{ order.orderNumber }} </span></label
            >
          </div>
          <button
            mat-button
            (click)="order.isOpen = !order.isOpen"
            class="custom-button"
          >
            <span class="button-label" *ngIf="!order.isOpen">{{
              TextoInfo
            }}</span>
            <span class="button-label" *ngIf="order.isOpen">
              Ver menos información</span
            >
            <mat-icon *ngIf="isAccordionOpen" style="color: #007bff"
              >expand_less</mat-icon
            >
            <mat-icon *ngIf="!isAccordionOpen" style="color: #007bff"
              >expand_more</mat-icon
            >
          </button>

          <app-pedidos-pendiente-visualizarguia
            [rutaPdf]="order.routePdfGuide"
          ></app-pedidos-pendiente-visualizarguia>

          <button
            type="button"
            class="btn btn-primary"
            style="margin-top: 10px"
            [disabled]="!isActive()"
            [routerLink]="['/PedidosPendientes/Detalle']"
            [queryParams]="{ tkt: order.tkt }"
          >
            Iniciar
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
