import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-modal-descargar-items',
  templateUrl: './modal-descargar-items.component.html',
  styleUrls: ['./modal-descargar-items.component.css']
})
export class ModalDescargarItemsComponent implements OnInit {

  @Input() classModal: any;
  @Output() enventoCerrarModalPadre = new EventEmitter<boolean>();
  @Output() descargarPdf = new EventEmitter<void>(false);
  etapa = {
    etapaDescargar: true,
    etapaOk: false
  }

  range: FormGroup;
  SinDatos:boolean;
  constructor() { 
    this.range = new FormGroup({
      start: new FormControl(),
      end: new FormControl()
    });
  }

  ngOnInit(): void {
    var date = new Date();
    this.range.get('start').setValue(moment()
                                    .startOf('isoWeek')
                                    .add(0, 'day'));
    this.range.get('end').setValue(moment()
                                  .startOf('isoWeek')
                                  .add(5, 'day'));
  }
  eventoCerrarModal() {
    this.enventoCerrarModalPadre.emit(true);
    this.etapa.etapaDescargar = true;
    this.etapa.etapaOk = false;
  }

  enventoDescargarItems(){
    localStorage.setItem('DateIni', JSON.stringify(this.range.value.start));
    localStorage.setItem('DateFin', JSON.stringify(this.range.value.end));
    this.descargarPdf.emit();

    let SIN_DATOS_PARA_DESCARGAR_HISTORIAL = new BehaviorSubject<boolean>(JSON.parse(localStorage.getItem('SIN_DATOS_PARA_DESCARGAR_HISTORIAL')));
    this.SinDatos=SIN_DATOS_PARA_DESCARGAR_HISTORIAL.value;
  }
}
