<div
  class="card-box"
  [ngClass]="{ 'small-item': isSmallVersion, 'item-despacho': !isSmallVersion }"
  (click)="onOpenModalItem()"
>
  <div
    class="estado-despacho"
    [ngClass]="{
      'small-despacho': isSmallVersion
    }"
    style="cursor: pointer"
  >
    <div
      [class]="
        'mb-0 p-1 text-size-xs status-text d-inline-block alert-' +
        getBarColor()
      "
      [ngClass]="{
        'small-status': isSmallVersion
      }"
    >
      <a [ngClass]="{ 'hide-class': isSmallVersion }">{{ getEstado() }} </a>
    </div>
    <div
      class=""
      *ngIf="guiaSinFirmar"
      [ngClass]="{ 'hide-class': isSmallVersion }"
    >
      <span
        class="circulo sinfirmar status tooltipCustom"
        [ngClass]="{ 'hide-class': isSmallVersion }"
        title="Te quedan guías por firmar"
      ></span>
    </div>
  </div>
  <div
    class="numero-pedido border-0"
    [ngClass]="{
      'small-order': isSmallVersion
    }"
  >
    <div class="text-color-label-desc">
      <span
        class="text-color-label-title"
        [ngClass]="{ 'hide-class': isSmallVersion }"
        >Nº de pedido:</span
      >
      {{ ItemHistoria.ID_CMD }}
    </div>
    <div class="text-color-label-desc">
      <span
        class="text-color-label-title"
        [ngClass]="{ 'hide-class': isSmallVersion }"
        >Producto:</span
      >
      <span>{{ truncarText(ItemHistoria.PRODUCTO) }}</span>
    </div>
    <div class="text-color-label-desc">
      <span
        class="text-color-label-title"
        [ngClass]="{ 'hide-class': isSmallVersion }"
        >Hora entrega:</span
      >
      {{ convertTimeToAMPM(ItemHistoria.HORA_SOL) }}
    </div>
    <div
      class="text-color-label-desc"
      *ngIf="isAccordionOpen && !isSmallVersion"
    >
      <span class="text-color-label-title">Hora llegada:</span>
      {{ ItemHistoria.LLEGAPCAM }}
    </div>
    <div
      class="text-color-label-desc"
      *ngIf="isAccordionOpen && !isSmallVersion"
    >
      <span class="text-color-label-title">Frecuencia:</span>
      {{ ItemHistoria.FRECUENCIA }} min
    </div>
    <div
      class="text-color-label-desc"
      *ngIf="isAccordionOpen && !isSmallVersion"
    >
      <span class="text-color-label-title">Cantidad total: </span>
      {{ ItemHistoria.CANT }} M<sup>3</sup>
    </div>

    <button
      mat-button
      (click)="toggleAccordion()"
      class="custom-button-despacho"
      [ngClass]="{ 'hide-class': isSmallVersion }"
    >
      <span class="button-label-despacho">{{ TextoInfo }}</span>
      <mat-icon *ngIf="isAccordionOpen" style="color: #007bff"
        >expand_less</mat-icon
      >
      <mat-icon *ngIf="!isAccordionOpen" style="color: #007bff"
        >expand_more</mat-icon
      >
    </button>
  </div>

  <div class="bottom-row" [ngClass]="{ 'hide-class': isSmallVersion }">
    <div 
      class="col-icon-img edit tooltipCustom"
      style="width: 20px; height: 20px"
      [ngClass]="{
        'icon-editar-formulario-inactivo': true,
        'icon-editar-formulario-activo': false
      }"
      title="Modificar"
      [style.pointer-events]="'none'"
      (click)="onOpenModal()"
    ></div><!--ItemHistoria.CONFIR != '1' ? 'none' : 'auto'  ItemHistoria.CONFIR != '1'  ItemHistoria.CONFIR == '1'-->
    <div
      class="col-icon-img truck tooltipCustom"
      style="width: 30px; height: 22px"
      [ngClass]="{
        'icon-camion-inactivo': !hasTrips,
        'icon-camion-activo': hasTrips
      }"
      [style.pointer-events]="!hasTrips ? 'none' : 'auto'"
      title="Ver seguimiento"
      [routerLink]="[
        '/Seguimiento/' +
          ItemHistoria.ID_CMD +
          '/' +
          ItemHistoria.FECHA_SOL +
          '/1'
      ]"
    ></div>
    <div
      class="col-icon-img guia tooltipCustom"
      style="width: 26px; height: 22px"
      [ngClass]="{
        'icon-guias-inactivo': !hasTrips,
        'icon-guias-activo': hasTrips
      }"
      title="Ver guía"
      [style.pointer-events]="!hasTrips ? 'none' : 'auto'"
      (click)="onOpenModalGuia()"
    ></div>
  </div>
</div>

<ng-template #contenidoModal class="modaldetalleEditar">
  <app-historial-programacion-item-editar
    (editarPedidoEmitter)="EditarPedido($event)"
    (CerrarModalEmitter)="onCierrenModal()"
    [ItemHistoria]="ItemHistoria"
  ></app-historial-programacion-item-editar>
  <app-alert></app-alert>
</ng-template>

<ng-template #contenidoModalGuias class="modalGuias">
  <div>
    <div class="header-container">
      <h6 class="text-title"></h6>
      <button
        mat-icon-button
        class="close-icon margin-cerrar"
        (click)="onCierrenModal()"
      >
        <mat-icon aria-label="Cerrar">close</mat-icon>
      </button>
    </div>
    <label class="text-color-label-title title-guia"
      >Guías de nº de pedido {{ ItemHistoria.ID_CMD }}
    </label>
    <div class="text-size-sm d-flex justify-content-center">
      <div class="lista-container">
        <app-historial-programacion-item-guias
          [Guias]="listaGuiasByItem"
        ></app-historial-programacion-item-guias>
      </div>
    </div>

    <div class="buttons-container buttons-container-guias">
      <a
        href="javascript:void(0)"
        (click)="onCierrenModal()"
        class="btn btn-volver"
        >Volver</a
      >&nbsp;
      <a
        href="javascript:void(0)"
        (click)="onCierrenModal()"
        class="btn btn-primary btn-back btn-l-md ok-btn"
        >Aceptar</a
      >
      <a
        href="javascript:void(0)"
        (click)="downloadAllGuias()"
        class="btn btn-primary btn-back btn-l-md download-all-btn"
        >Descargar todo</a
      >
    </div>
  </div>
  <app-alert></app-alert>
</ng-template>

<ng-template #contenidoModal class="modaldetalleEditar">
  <app-historial-programacion-item-editar
    (editarPedidoEmitter)="EditarPedido($event)"
    (CerrarModalEmitter)="onCierrenModal()"
    [ItemHistoria]="ItemHistoria"
  ></app-historial-programacion-item-editar>
  <app-alert></app-alert>
</ng-template>

<ng-template #contentItem class="modalItem">
  <div class="p-3">
    <div class="d-flex flex-row-reverse">
      <button mat-icon-button class="close-icon close-button-item">
        <mat-icon aria-label="Cerrar" (click)="onCierrenModalItem()"
          >close</mat-icon
        >
      </button>
    </div>
    <div class="item-despacho m-0">
      <div class="estado-despacho together" style="cursor: pointer">
        <div
          [class]="
            'mb-0 p-1 text-size-xs status-text d-inline-block mr-5 alert-' +
            getBarColor()
          "
        >
          <a>{{ getEstado() }} </a>
        </div>
        <div class="" *ngIf="guiaSinFirmar">
          <span class="circulo sinfirmar status"></span>
        </div>
      </div>
      <div class="numero-pedido border-0">
        <div class="text-color-label-desc">
          <span class="text-color-label-title">Nº de pedido:</span>
          {{ ItemHistoria.ID_CMD }}
        </div>
        <div class="text-color-label-desc">
          <span class="text-color-label-title">Producto:</span>
          <span>{{ truncarText(ItemHistoria.PRODUCTO) }}</span>
        </div>
        <div class="text-color-label-desc">
          <span class="text-color-label-title">Hora entrega:</span>
          {{ convertTimeToAMPM(ItemHistoria.HORA_SOL) }}
        </div>
        <div class="text-color-label-desc" *ngIf="isAccordionOpen">
          <span class="text-color-label-title">Hora llegada:</span>
          {{ ItemHistoria.LLEGAPCAM }}
        </div>
        <div class="text-color-label-desc" *ngIf="isAccordionOpen">
          <span class="text-color-label-title">Frecuencia:</span>
          {{ ItemHistoria.FRECUENCIA }} min
        </div>
        <div class="text-color-label-desc" *ngIf="isAccordionOpen">
          <span class="text-color-label-title">Cantidad total: </span>
          {{ ItemHistoria.CANT }} M<sup>3</sup>
        </div>

        <button
          mat-button
          (click)="toggleAccordion()"
          class="custom-button-despacho"
        >
          <span class="button-label-despacho">{{ TextoInfo }}</span>
          <mat-icon *ngIf="isAccordionOpen" style="color: #007bff"
            >expand_less</mat-icon
          >
          <mat-icon *ngIf="!isAccordionOpen" style="color: #007bff"
            >expand_more</mat-icon
          >
        </button>
      </div>

      <div class="bottom-row">
        <div
          class="col-icon-img edit"
          style="width: 25px; height: 25px"
          [ngClass]="{
            'icon-editar-formulario-inactivo': ItemHistoria.CONFIR == '1',
            'icon-editar-formulario-activo': ItemHistoria.CONFIR != '1'
          }"
          (click)="onOpenModal()"
        ></div>
        <div
          class="col-icon-img truck"
          style="width: 45px; height: 30px"
          [ngClass]="{
            'icon-camion-inactivo': !hasTrips,
            'icon-camion-activo': hasTrips
          }"
          (click)="onCierrenModalItem()"
          [routerLink]="[
            '/Seguimiento/' +
              ItemHistoria.ID_CMD +
              '/' +
              ItemHistoria.FECHA_SOL +
              '/1'
          ]"
        ></div>
        <div
          class="col-icon-img guia"
          style="width: 35px; height: 30px"
          [ngClass]="{
            'icon-guias-inactivo': !hasTrips,
            'icon-guias-activo': hasTrips
          }"
          (click)="onOpenModalGuia()"
        ></div>
      </div>
    </div>
  </div>
</ng-template>
