import { ScrollStrategyOptions } from '@angular/cdk/overlay';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { OrderService } from 'src/app/services/pedidos/pedidos.service';
import { ReturnReason } from 'src/app/shared/models/returnReason';

@Component({
  selector: 'app-historial-seguimiento-comentarios',
  templateUrl: './historial-seguimiento-comentarios.component.html',
  styleUrls: ['./historial-seguimiento-comentarios.component.css'],
})
export class HistorialProgramacionSeguimientoComentariosComponent
  implements OnInit
{
  isAccordionOpen = true;
  dialogRef: MatDialogRef<any>;
  returnReasons: ReturnReason[] = [];

  @Input() pedido: any;
  @Output() VolverSeguimientoEmitter = new EventEmitter<void>();
  @Output() GuardarComentariosEmitter = new EventEmitter<void>();

  @ViewChild('contenidoModal') contenidoModal!: TemplateRef<any>;
  @ViewChild('contenidoModalEditar') contenidoModalEditar!: TemplateRef<any>;

  placeholderArrivalTime = '';
  placeholderStartUnloadingTime = '';
  placeholderEndUnloadingTime = '';
  isHormigon=false;
  constructor(
    private dialog: MatDialog,
    private orderService: OrderService,
    private scrollStrategyOptions: ScrollStrategyOptions
  ) {}

  ngOnInit(): void {
    debugger
    this.getReturnReasons();
    this.pedido.MX_ADICIONAL_UNO_CLI = this.pedido.MX_ADICIONAL_UNO_CLI != '';
    this.pedido.MX_ADICIONAL_DOS_CLI = this.pedido.MX_ADICIONAL_DOS_CLI != '';
    this.placeholderArrivalTime = this.pedido.MX_HORA_CUATRO_H;
    this.isHormigon=this.pedido.NEG =='3400' || this.pedido.NEG =='5000' ? true:false;
    if (this.pedido.MX_HORA_CUATRO_H == '')
      this.placeholderArrivalTime = this.pedido.HORA_CUATRO_H;
    this.placeholderStartUnloadingTime = this.pedido.MX_HORA_CINCO_H;
    if (this.pedido.MX_HORA_CINCO_H == '')
      this.placeholderStartUnloadingTime = this.pedido.HORA_CINCO_H;
    this.placeholderEndUnloadingTime = this.pedido.MX_HORA_SEIS_H;
    if (this.pedido.MX_HORA_SEIS_H == '')
      this.placeholderEndUnloadingTime = this.pedido.HORA_SEIS_H;
    this.pedido.MX_ADICIONAL_UNO_CLI = this.pedido.MX_ADICIONAL_UNO_H == 'true';
    this.pedido.MX_ADICIONAL_DOS_CLI = this.pedido.MX_ADICIONAL_DOS_H == 'true';
    this.pedido.MX_MOTIVO_RETORNO_CLI = this.pedido.MX_MOTIVO_RETORNO;
    if (
      this.pedido.MX_ADICIONAL_TRES_CLI != undefined &&
      this.pedido.MX_ADICIONAL_TRES_CLI != ''
    )
      this.pedido.MX_ADICIONAL_TRES_CLI =
        this.getAdicionalTresFromConcatenado();
  }

  toggleAccordion() {
    this.isAccordionOpen = !this.isAccordionOpen;
  }

  onOpenModal() {
    this.dialogRef = this.dialog.open(this.contenidoModal, {
      maxWidth: '90%',
      scrollStrategy: this.scrollStrategyOptions.noop(),
    });
  }

  onOpenModalEditar() {
    this.dialogRef = this.dialog.open(this.contenidoModalEditar, {
      maxWidth: '90%',
      scrollStrategy: this.scrollStrategyOptions.noop(),
    });
  }

  onCierrenModal() {
    this.dialogRef.close();
  }

  Volver() {
    this.VolverSeguimientoEmitter.emit();
  }

  guardar() {
    this.onCierrenModal();
    this.pedido.MX_ADICIONAL_TRES_CLI =
      `${this.pedido.MX_HORA_CUATRO_CLI}|${this.pedido.MX_HORA_CINCO_CLI}|` +
      `${this.pedido.MX_HORA_SEIS_CLI}|${this.getAdicionalUno()}|` +
      `${this.getAdicionalDos()}|${
        this.pedido.MX_M3_RETORNO_CLI
      }|${this.getMotivoRetorno()}|` +
      `${this.pedido.MX_ADICIONAL_TRES_CLI}|${this.pedido.MX_COMENTARIOS_HORMI}|${this.pedido.MX_COMENTARIOS_ESTRUCT}|${this.pedido.MX_COMENTARIOS_CICLOS}`;
    this.GuardarComentariosEmitter.emit(this.pedido);
  }

  getAdicionalTresFromConcatenado() {
    return this.pedido.MX_ADICIONAL_TRES_CLI.split('|')[7];
  }

  getAdicionalUno() {
    if (this.pedido.MX_ADICIONAL_UNO_CLI == this.pedido.MX_ADICIONAL_UNO_H)
      return '';
    return this.pedido.MX_ADICIONAL_UNO_CLI;
  }

  getAdicionalDos() {
    if (this.pedido.MX_ADICIONAL_DOS_CLI == this.pedido.MX_ADICIONAL_DOS_H)
      return '';
    return this.pedido.MX_ADICIONAL_DOS_CLI;
  }

  getMotivoRetorno() {
    if (this.pedido.MX_MOTIVO_RETORNO_CLI == this.pedido.MX_MOTIVO_RETORNO)
      return '';
    return this.pedido.MX_MOTIVO_RETORNO_CLI;
  }
  getReturnReasons() {
    this.orderService.getReturnReasons().subscribe((data) => {
      this.returnReasons = data;
    });
  }
}
