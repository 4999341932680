import { ScrollStrategyOptions } from '@angular/cdk/overlay';
import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { AlertService } from 'src/app/services/auth';
import { HistorialPedidosService } from 'src/app/services/historial-pedidos/historial-pedidos.service';
import { MenuService } from 'src/app/services/menu/menu-service';
import { ViajesService } from 'src/app/services/viajes-cli/viajes.service';
import { Utilidades } from 'src/app/shared/FuntionsGlobales/Utilidades';
import { DataHistorialPedidosModel } from 'src/app/shared/models/HistorialPedidosModel';

@Component({
  selector: '[app-historial-programacion-item]',
  templateUrl: './historial-programacion-item.component.html',
  styleUrls: ['./historial-programacion-item.css'],
})
export class HistorialProgramacionItemComponent implements OnInit {
  enumEstados = {
    Pendiente: '1',
    Programado: '2',
    Rechazado: '3',
    Eliminado: '4',
    Entregado: '10',
  };
  @Input() ItemHistoria = new DataHistorialPedidosModel();
  @Input() isSmallVersion = false;
  isAccordionOpen = false;
  TextoInfo: string = 'Ver más información';

  @ViewChild('contenidoModal') contenidoModal!: TemplateRef<any>;
  @ViewChild('contentItem') contentItem!: TemplateRef<any>;
  @ViewChild('contenidoModalGuias') contenidoModalGuias!: TemplateRef<any>;
  dialogRef: MatDialogRef<any>;
  dialogRefItem: MatDialogRef<any>;

  listaGuias = [];
  listaGuiasByItem = [];
  guiaSinFirmar = false;
  hasTrips = false;

  constructor(
    private menu: MenuService,
    private router: Router,
    private dialog: MatDialog,
    private pedido: HistorialPedidosService,
    private alertService: AlertService,
    private ViajesServ: ViajesService,
    private scrollStrategyOptions: ScrollStrategyOptions
  ) {}

  ngOnInit(): void {
    localStorage.removeItem('listaGuias');
    this.getGuias();
    if (!this.isNotAvailable())
      this.hasTrips = this.ItemHistoria.TIENEVIAJES == '1';
  }
  getEstado() {
    // debugger;
    if (this.ItemHistoria.CONFIR == '1') {
      return 'Confirmado';
    } else {
      switch (this.ItemHistoria.ESTADO) {
        case this.enumEstados.Pendiente:
          return 'Pendiente';
        case this.enumEstados.Programado:
          return 'Programado';
        case this.enumEstados.Rechazado:
          return 'Anulado';
        case this.enumEstados.Eliminado:
          return 'Anulado';
        case this.enumEstados.Entregado:
          return 'Entregado';

        default:
          return '';
      }
    }
  }

  isNotAvailable() {
    if (this.ItemHistoria.CONFIR == '1') return false;
    return (
      this.ItemHistoria.ESTADO === this.enumEstados.Rechazado ||
      this.ItemHistoria.ESTADO === this.enumEstados.Eliminado
    );
  }

  getAvailabilityColor() {
    switch (this.ItemHistoria.ESTADO) {
      case this.enumEstados.Pendiente:
        return 'text-color-warning-primary';

      case this.enumEstados.Rechazado:
        return 'text-color-red-primary';

      case this.enumEstados.Eliminado:
        return 'text-color-light-blue-800';

      case this.enumEstados.Programado:
      default:
        return '';
    }
  }
  getBarColor() {
    if (this.ItemHistoria.CONFIR == '1') {
      return 'info';
    } else {
      switch (this.ItemHistoria.ESTADO) {
        case this.enumEstados.Pendiente:
          return 'warning';

        case this.enumEstados.Programado:
          return 'success';

        case this.enumEstados.Rechazado:
          return 'negative';

        case this.enumEstados.Eliminado:
          return 'negative';
        case this.enumEstados.Entregado:
          return 'success';
        default:
          return '';
      }
    }
  }

  convertTimeToAMPM(hora) {
    return Utilidades.getTimeAMPM(hora);
  }

  showDetails() {
    this.router.navigate([
      '/historialprogramacion/' + this.ItemHistoria.ID_WEB,
    ]);
  }

  truncarText(Texto: string) {
    if (Texto != undefined) {
      return Texto.length > 30 ? Texto.substring(0, 27) + '...' : Texto;
    }
  }

  toggleAccordion() {
    this.isAccordionOpen = !this.isAccordionOpen;
    if (this.isAccordionOpen) {
      this.TextoInfo = 'Ver menos información';
    } else {
      this.TextoInfo = 'Ver más información';
    }
  }

  onOpenModal() {
    //FIXME: Cambiar el tamaño del modal según el tamaño de la pantalla
    this.dialogRef = this.dialog.open(this.contenidoModal, {
      maxWidth: '90%',
      scrollStrategy: this.scrollStrategyOptions.noop(),
    });
  }

  onOpenModalItem() {
    if (!this.isSmallVersion) return;
    this.dialogRefItem = this.dialog.open(this.contentItem, {
      maxWidth: '95%',
      panelClass: 'dialog-without-padding',
      scrollStrategy: this.scrollStrategyOptions.noop(),
    });
  }

  onCierrenModalItem() {
    this.dialogRefItem.close();
  }

  onCierrenModal() {
    this.dialogRef.close();
  }

  onOpenModalGuia() {
    this.dialogRef = this.dialog.open(this.contenidoModalGuias, {
      maxWidth: '100%', // Puedes ajustar el ancho según tus necesidades
      scrollStrategy: this.scrollStrategyOptions.noop(),
    });
    if (this.listaGuias.length == 0) {
      this.getAllGuias();
    }
  }

  getAllGuias() {
    let PedidoSelect = {
      NUMERO_PEDID_H: this.ItemHistoria.ID_CMD,
      FECHA_PED_DATE_H: this.ItemHistoria.FECHA_SOL,
    };
    this.ViajesServ.GetGuiasCliente(PedidoSelect).subscribe((result) => {
      if (result?.unknownError) {
        return;
      }
      if (result?.datos) this.listaGuiasByItem = result?.datos[0].VIAJESH;
    });
  }

  EditarPedido(obj) {
    this.ItemHistoria = { ...obj };
    obj.FECHA_SOL = this.formatDate(obj.FECHA_SOL); //ajusto la fecha l formato del servicio
    this.pedido.updatePedido(obj).subscribe(
      (pedidoResult) => {
        if (Object.keys(pedidoResult).indexOf('id_error') >= 0) {
          this.alertService.error('Error al actualizar pedido.');
        } else {
          this.alertService.success('Pedido actualizado.');
          let anyError =
            pedidoResult.id_error ||
            pedidoResult.unknownError ||
            !pedidoResult.id;

          setTimeout(() => {
            this.onCierrenModal();
          }, 2000);
        }
      },
      (error) => {
        this.alertService.error(
          'Error actualizar el pedido, reintente de nuevo'
        );
      }
    );
  }

  getGuias() {
    let PedidoSelect = {
      NUMERO_PEDID_H: this.ItemHistoria.ID_CMD,
      FECHA_PED_DATE_H: this.ItemHistoria.FECHA_SOL,
    };
    this.guiaSinFirmar = this.ItemHistoria.GUIA_PENDIENTE_FIRMA == '1';
    /*this.ViajesServ.GetGuiasCliente(PedidoSelect).subscribe((result) => {
      if (result?.unknownError) {
        return;
      }
      if (result?.datos) {
        this.listaGuiasByItem = result?.datos[0].VIAJESH;

        if (this.listaGuiasByItem.length >0) {
          this.AddListGuiasStorage(this.listaGuiasByItem);

          for (const guia of this.listaGuiasByItem) {
            if (guia.MX_CLIENTE_FIRMA_H === 0 || guia.MX_CLIENTE_FIRMA_H === '') {
              this.guiaSinFirmar = true;
              break;
            }
          }
        }
        
      } else {
        return;
      }
    });*/
  }

  downloadAllGuias() {
    for (const guia of this.listaGuiasByItem) {
      this.downloadGuia(guia);
    }
  }

  downloadGuia(guia) {
    let url = guia.PDF_GUIA_H;
    window.open(url, '_blank');
  }

  AddListGuiasStorage(ListaGuiasByItem: any[]) {
    const itemLocal = localStorage.getItem('listaGuias');

    if (itemLocal !== null) {
      let HistoryListaGuias = new BehaviorSubject<any>(JSON.parse(itemLocal));

      this.listaGuias.push(...ListaGuiasByItem);

      if (
        HistoryListaGuias.value != null &&
        HistoryListaGuias.value.length > 0
      ) {
        this.listaGuias.push(...HistoryListaGuias.value);
      }

      localStorage.setItem('listaGuias', JSON.stringify(this.listaGuias));
    } else {
      this.listaGuias.push(...ListaGuiasByItem);
      localStorage.setItem('listaGuias', JSON.stringify(this.listaGuias));
    }
  }
  formatDate(inputDate: string): string {
    const year = inputDate.slice(0, 4);
    const month = inputDate.slice(4, 6);
    const day = inputDate.slice(6, 8);

    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
  }
}
