<div
  *ngFor="let item of Guias; let last = last"
  class="list-item"
  [class.last]="last"
>
  <div class="item-name">Nº de guía: {{ item.FOLIO_H }}</div>
  <div class="" *ngIf="isUnsigned(item)">
    <span
      class="circulo sinfirmar col-icon-img"
      title="Te quedan guías por firmar"
    ></span>
  </div>
  <div
    class="item-icon"
    target="_blank"
    (click)="abrirEnNuevaPestana(item.PDF_GUIA_H)"
  >
    <div class="col-icon-img" title="Guias">
      <img
        src="assets/images/icons/icon_guia_firmada_activo.svg"
        class="img-fluid"
        alt=""
      />
    </div>
  </div>
  <hr class="divider" />
</div>
