<div class="dashboard dashboard-center" style="box-shadow: none;" style="padding-top: 48px;">
    <div class="card-box card-perfil" style="text-align: center;box-shadow: none;" > 
    <h2 class="text-center">Contraseña cambiada</h2>
    <p class="text-center">Su contraseña fue cambiada con éxito.</p>
    <div class="number-ticket">
        <i class="icon-form-ok"></i>
    </div>
    <div class="buttons">
        <a type="button" [routerLink]="['/']" class="btn btn-primary btn-editar-perfil btn-l-md ">Finalizar</a>
    </div>
</div>