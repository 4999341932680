<!--login-->
<div class="form p-4">
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" *ngIf="etapa==1 || etapa==0">
        <div class="header-form">
            <div class="title-form">¿Has olvidado tu contraseña?</div>
            <div class="caption-form">{{mensajeRecuperacion}}</div>
        </div>

        <app-alert></app-alert>

        <div class="form-group" *ngIf="etapa==0">
            <input type="text" class="form-input mat-input form-control" 
            name="rutUser" 
            id="rutUser" 
            formControlName="rutUser" 
            [ngClass]="{ 'is-invalid': submitted && f.rutUser.errors }" 
            placeholder="Ej: 12345678k"
            (keyup)="onDisableButton()"
            (paste)="onDisableButton()">
            <label for="rutUser" class="mat-label">Rut</label>
            <div  class="invalid-feedback pull-left">
                <div  class="pull-left">
                    El Rut es requerido
                </div>
            </div>
            <div class="help-text">Ingresa tu Rut sin puntos ni guion</div>

        </div>
        <div class="form-group" class="form-group" *ngIf="etapa==1">
            <input type="text" class="form-input mat-input form-control" 
            name="rutEmp" 
            id="rutEmp" formControlName="rutEmp" 
            [ngClass]="{ 'is-invalid': submitted && f.rutEmp.errors }" 
            placeholder="Ej: 12345678K"
            (keyup)="onDisableButton()"
            (paste)="onDisableButton()">
            <label for="rutEmp" class="mat-label">Rut Empresa</label>
            <div class="help-text">Ingresa tu Rut de empresa sin puntos ni guion</div>
            <div *ngIf="btnDisable" class="invalid-feedback pull-left">
                <div *ngIf="btnDisable" class="pull-left">
                    El Rut de la empresa es requerido
                </div>
            </div>
        </div>

        <div class="buttons" >
            <button [ngClass]="'btn btn-primary'" [disabled]="btnDisable" type="submit">Siguiente</button>
        </div>
    </form>

    <div class="form-group" *ngIf="etapa==2">
        <div class="respuesta-form">
            <div class="content pt-1">
                <h2 class="text-center">Contraseña enviada</h2>
                <p class="text-center">Hemos enviado tu contraseña al correo electrónico {{result.mail}}.</p>
                <div class="number-ticket mb-0">
                    <i class="icon-form-ok mb-0"></i>
                </div>
            </div>
        </div>
    </div>
    <div class="text-center mt-4 text-size-sm">
        <a [routerLink]="['/login']">
            <div class="mb-3"><i><img src="assets/images/icons/arrow-right.svg" width="16" class="img-fluid" alt=""></i></div>
            Volver a inicio de sesión
        </a>
    </div>
</div>