<div class="dashboard">

  <div class="mensajes mensaje-warning content-desktop-card ng-star-inserted mensajeDeInfoDeEdición">
    <div class="mensajes-text">
      <strong>Las modificaciones de pedidos</strong> se pueden realizar hasta las <strong>15 horas</strong> del día
      anterior del despacho, o hasta que se hayan confirmado
    </div>
  </div>

  <div class="programacion-de-despacho content-desktop-card" *ngIf="foundItem">
    <div class="card-box px-0 card-w-100">
      <div class="card-box-header px-3">
        <div class="guia">
          <strong [class]="'text-size-md pendiente-color '" style="color:#0033A1">
            {{ getEstado(productItem.ESTADO) }}</strong>
        </div>
        <div class="tools" >
          <!-- Falta editar la lógica del boton y el modal -->
          <div style="cursor: pointer;" (click)="openModalEditarPedido(productItem)"
            class="icon-hide icon-edit edit-file mr-3"></div>

          <div style="cursor: pointer;" (click)="openModalEliminarPedido()" class="icon-hide remove-file mr-3"></div>
        </div>
      </div>

      <div class="card-box-body border-top-1 pt-4 pb-2 border-bottom-0">
        <div class="details px-3">
          <dl class="pt-0">
            <dt><strong class="text-color-blue-secondary">Obra</strong></dt>
            <dd>{{ productItem.OBRA }}</dd>
          </dl>

          <dl>
            <dt><strong class="text-color-blue-secondary">Recibe</strong></dt>
            <dd>{{ productItem.NOMBRE_RECEPCIONA }}</dd>
          </dl>

          <dl>
            <dt><strong class="text-color-blue-secondary">Producto</strong></dt>
            <dd>{{ productItem.PRODUCTO }} </dd>
          </dl>

          <dl class="border-bottom-1">
            <dt><strong class="text-color-blue-secondary">Cantidad total</strong></dt>
            <dd>{{ productItem.CANT }} m<sup>3</sup></dd>
            <!-- <input (keyup)="onEdit($event)"> -->
          </dl>

          <div class="row">
            <div class="col-6">
              <dl>
                <dt><strong class="text-color-blue-secondary">Fecha</strong></dt>
                <dd>{{ productItem.FECHA_SOL }}</dd>
              </dl>
            </div>

            <div class="col-6 border-left-1">
              <dl>
                <dt><strong class="text-color-blue-secondary">Hora</strong></dt>
                <dd>{{ convertTimeToAMPM(productItem.HORA_SOL) }}</dd>
              </dl>
            </div>
          </div>

          <dl>
            <dt><strong class="text-color-blue-secondary">Tamaño de cargas</strong></dt>
            <dd>{{ productItem.T_CARGA }} m<sup>3</sup></dd>
          </dl>

          <dl>
            <dt><strong class="text-color-blue-secondary">Frecuencia de camiones</strong></dt>
            <dd>Un camión cada {{ productItem.FRECUENCIA }} minutos</dd>
          </dl>
          <div class="row">
            <div class="col-6">
              <dl>
                <dt><strong class="text-color-blue-secondary">Toma de muestras</strong></dt>
                <dd>{{ productItem.MUESTRA }}</dd>
              </dl>
            </div>

           <div class="col-6 border-left-1">
            <dl>
              <dt><strong class="text-color-blue-secondary">Servicio</strong></dt>
              <dd>{{ getServicio(productItem.SERVICIOS) }}</dd>
            </dl>
           </div>
           
          </div>
         
          <dl>
            <dt><strong class="text-color-blue-secondary">Especificaciones</strong></dt>
            <dd>{{ productItem.ESPECIFICAC }}</dd>
          </dl>

        </div>
      </div>
      <!--end card-->
    </div>
    <!--end card box-->

  </div>

  <div class="mensajes mensaje-warning content-desktop-card" *ngIf="!foundItem">
    <div class="mensajes-text">
      <strong>Tu búsqueda no ha arrojado resultados.</strong>
    </div>
  </div>
  <div class="button-container">
    <div class="buttons">
      <button type="button" class="btn btn-primary btn-back btn-l-md" (click)="backHistorialList()"><i
          class="icon-hide icon-back"></i>Volver</button>
    </div>
  </div>
</div>

<app-modal-anular-pedido  [classModal]="classModalEliminarPedido" [finishedProcess]="finishedProcess"
  [successfulProcess]="successfulProcess" (cerrarModalEmitter)="closeModalEliminarPedido()"
  (eliminarPedidoEmitter)="deletePedido()">
</app-modal-anular-pedido>

<app-modal-editar-pedido  [classModal]="classModalEditarPedido" [cantidadSelecionada]="itemSelected"
  [finishedProcess]="finishedProcess" [successfulProcess]="successfulProcess" [objetoSeleccionado]="objSelected"
  (cerrarModalEmitter)="closeModalEditarPedido()" (editarPedidoEmitter)="editPedido($event)">
</app-modal-editar-pedido>
