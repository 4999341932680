import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AccountService } from '../auth';
import { Utilidades } from 'src/app/shared/FuntionsGlobales/Utilidades';

@Injectable({
  providedIn: 'root',
})
export class EncuestaService {
  private endpoint: string;
  private endpointEncuestaCli: string;
  private key: string;

  constructor(
    private http: HttpClient,
    PerfilUsuarioComponent: AccountService
  ) {
    this.endpoint = environment.ResourcePolpaico + environment.Encuesta;
    this.endpointEncuestaCli = environment.ResourcePolpaico + environment.EncuestaCli;
    this.key = PerfilUsuarioComponent.userValue.KEY;
  }

  saveOldSurvey(data) {
    return this.http.get<any>(this.endpointEncuestaCli,
      {
        headers:
        {
          'Key': this.key,
          'pregunta':Utilidades.replaceSpecialCharacters(data["pregunta"]),
          'puntos': data["puntos"],
          'categorias': Utilidades.replaceSpecialCharacters(data["categorias"]),
          'comentario': Utilidades.removeUnwantedCharacters(Utilidades.replaceSpecialCharacters(data["comentario"])),
          'apartado': data["apartado"],
          'codobra':data['CodObra'],
          'nombreobra':data['NombreObra']
        }
      });
  }

  SaveEncuesta(data) {
    const headers = {
      Key: this.key,
      pregunta1: Utilidades.replaceSpecialCharacters(data['pregunta'][0]),
      pregunta2: Utilidades.replaceSpecialCharacters(data['pregunta'][1]),
      pregunta3: Utilidades.replaceSpecialCharacters(data['pregunta'][2]),
      puntos1: data['puntos'].split(',')[0],
      puntos2: data['puntos'].split(',')[1],
      puntos3: data['puntos'].split(',')[2],
      comentario: Utilidades.removeUnwantedCharacters(
        Utilidades.replaceSpecialCharacters(data['comentario'])
      ),
      codobra: data['CodObra'],
      nombreobra: data['NombreObra'],
      nro_guia: data['NroGuia'],
    };
    console.log(headers);
    return this.http.get<any>(this.endpoint, {
      headers: headers,
    });
  }
}
