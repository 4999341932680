import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MenuService } from 'src/app/services/menu/menu-service';
import { Constantes } from 'src/app/shared/Constantes/constantes';
import {
  CertificadosFilterModel,
  StatusCertificado,
} from 'src/app/shared/models/CertificadosFilterModel';
import { ComboType } from 'src/app/shared/models/ComboType';

@Component({
  selector: 'app-pedidos-pendiente-filtro',
  templateUrl: './pedidos-pendiente-filtro.component.html',
  styleUrls: ['./pedidos-pendiente-filtro.component.css'],
})
export class PedidosPendienteFiltroComponent implements OnInit {
  @Input() classModal: string = Constantes.classCierreModal;
  @Output() eventoFiltrar = new EventEmitter<CertificadosFilterModel>();
  @Input() dataObras: ComboType[] = new Array();
  @Input() selectObra: string;
  @Input() selectCodigoObra: string;
  @Input() fechaMinima;
  @Input() fechaMaxima;
  @Input() numberOrders: number = 0;
  @Input() numberOrdersFiltered: number = 0;
  @Input() numberOrdersDelivered: number = 0;
  @Input() numberRejectedOrders: number = 0;
  @Input() numberPendingOrders: number = 0;
  @Input() isStatusFilterEnabled: boolean = false;
  campaignOne: FormGroup;

  selectNumero: string = '';
  formEdicion: FormGroup;
  range: FormGroup;
  statusSelected: number = 0;

  obrasArray: ComboType[];
  @ViewChild('InputSearch', { static: true }) nameSearchElement: ElementRef;
  @ViewChild('contenidoModal') contenidoModal!: TemplateRef<any>;

  dialogRef: MatDialogRef<any>;
  constructor(
    private menu: MenuService,
    nameSearchElement: ElementRef,
    private dialog: MatDialog
  ) {
    this.nameSearchElement = nameSearchElement;

    this.formEdicion = new FormGroup({
      numeroBuscar: new FormControl(this.selectNumero),
    });

    this.range = new FormGroup({
      start: new FormControl(),
      end: new FormControl(),
    });
  }
  get numeroBuscar() {
    return this.formEdicion.get('numeroBuscar');
  }
  ngOnInit(): void {
    this.obrasArray = this.dataObras;
    this.reLoadObrasFilter();
  }

  reLoadObrasFilter() {
    if (this.dataObras.length > 0) {
      this.obrasArray = this.dataObras;
    } else {
      setTimeout(() => {
        this.reLoadObrasFilter();
      }, 100);
    }
  }

  onOpenModal() {
    this.dialogRef = this.dialog.open(this.contenidoModal, {
      maxWidth: '90%', // Puedes ajustar el ancho según tus necesidades
    });
  }

  onCierrenModal() {
    this.dialogRef.close();
  }

  onFiltrar() {
    let dataFiltrar = new CertificadosFilterModel();

    dataFiltrar.numeroFiltar = this.numeroBuscar.value;
    dataFiltrar.fechaDesde = this.range.value.start;
    dataFiltrar.fechaHasta = this.range.value.end;
    dataFiltrar.hasFilter = true;
    dataFiltrar.status = this.getStatus();

    this.eventoFiltrar.emit(dataFiltrar);
    this.onCierrenModal();
  }

  getStatus() {
    switch (this.statusSelected) {
      case 0:
        return StatusCertificado.All;
      case 1:
        return StatusCertificado.Delivered;
      case 3:
        return StatusCertificado.Pending;
      default:
        return StatusCertificado.Rejected;
    }
  }

  onKey(value) {
    this.obrasArray = [];
    this.selectSearch(value);
  }
  selectSearch(value: string) {
    let filter = value.toLowerCase();
    for (let i = 0; i < this.dataObras.length; i++) {
      let option = this.dataObras[i];
      if (
        option.value.toLowerCase().indexOf(filter) >= 0 ||
        option.id.toLowerCase().indexOf(filter) >= 0
      ) {
        this.obrasArray.push(option);
      }
    }
  }

  selectStatus(status: number) {
    this.statusSelected = status;
  }

  clearDate(event) {
    this.range.reset();
    event.stopPropagation();
  }
}
