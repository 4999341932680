export enum EnumRoles {
    
    menuPrincipal="dashboard",
    editarperfil="editarperfil",
    cambiarpassword="cambiarpassword",
    notificaciones="notificaciones",
    historialprogramacion="historialprogramacion",
    historialprogramaciondetalles="historialprogramaciondetalles",
    PedidosPorDespachar="pedidospendiente",
    HistorialMixer="pedidosEntregados"
}
