import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { AccountService, AlertService } from 'src/app/services/auth';
import { RecoverPasswordResultModel } from 'src/app/shared/models/UserLoginModel.model';

@Component({
  selector: 'app-recoverpassword',
  templateUrl: './recoverpassword.component.html'
})
export class RecoverpasswordComponent implements OnInit {

  loginForm: FormGroup;
  loading = false;
  submitted = false;
  btnDisable=true
  etapa = 0;
  returnUrl: string;
  mensajeRecuperacion:string="Indícanos tu Rut para renviarte tu contraseña"
  result = new RecoverPasswordResultModel();
  private rutInterno="";
  constructor(private formBuilder: FormBuilder,
    
    private route: ActivatedRoute,
    private accountService: AccountService,
    private alertService: AlertService) { }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      rutUser: [''],
      rutEmp:['']
    });

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }
  get f() { return this.loginForm.controls; }
  onDisableButton()
  {
    if (this.etapa==0 || this.etapa==1 )
    {
      this.btnDisable= this.f.rutUser.value.length==0;
    }else
    {
      this.btnDisable= this.f.rutEmp.value.length==0;
    }
    

  }
  onSubmit() {
    this.submitted = true;
    this.rutInterno=this.f.rutUser.value;
    let rutEmpresa:string=this.f.rutEmp.value;
    // reset alerts on submit
    this.alertService.clear();
    
    // stop here if form is invalid
    if (this.rutInterno.length==0 && this.etapa==0) {
      return;
    }
    if (rutEmpresa.length==0 && this.etapa==1) {
      return;
    }
    this.loading = true;
    
    this.accountService.recoverPassword(this.rutInterno.replace(/[.]/g,"").replace(/[-]/g,""),rutEmpresa.replace(/[.]/g,"").replace(/[-]/g,""))
      .pipe(first())
      .subscribe(
        data => {
          if (data === null) {
            this.alertService.error("Ha habido un error, Intentelo nuevamente.");
            this.loading = false;
          } else {
            this.result.estado = data["Estado"];
            this.result.mail = data["mail"];
            if (this.result.estado === "Enviado") {
              this.etapa=2;
            } else {
              if (this.result.estado=="Error Existe más de un cliente")
              {
                this.alertService.clear();
                this.mensajeRecuperacion="Indícanos el Rut de la empresa para enviar tu contraseña."
                this.etapa=1;
              }else
              {
                if ( this.etapa==1)
                {
                  this.alertService.error("El rut empresa es incorrecto.");
                }else{
                  this.alertService.error("El usuario es incorrecto.");
                }
              }
            }
          }
        },
        error => {
          this.alertService.error("Lo sentimos ocurrio un error, reintente");
          this.loading = false;
        });
  }

}
