<div class="dashboard-nav-bottom">
  <a [routerLink]="['/dashboard']" class="link-logo"><img src="/assets/images/html/logo-polpaico-menu.svg"
      class="img-logo" alt=""></a>
  <nav>
    <ul class="nav-bottom">
      <li *appPerfil="perfilView.btnMenuPrincipal" [style]="tamanoDefault">
        <a routerLinkActive="active" [routerLink]="['/dashboard']"
          [ngClass]="{'active': dashboardvisualizar.isActive}">
          <i class="icon icon-home"></i>Inicio</a>
      </li>
      
    </ul>
    <!--workaround para marcar coo active el menú principal-->
    <a routerLink="/dashboard" routerLinkActive #dashboardvisualizar="routerLinkActive"
      style="display: none"></a>

    <a routerLink="/guiadespachovisualizar" routerLinkActive #guiadespachovisualizar="routerLinkActive"
      style="display: none"></a>


  </nav>
</div>
