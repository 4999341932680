import { Component, OnInit } from '@angular/core';
import { AlertService } from 'src/app/services/auth';
import { AccountService } from 'src/app/services/auth/account.service';
import { MenuService } from 'src/app/services/menu/menu-service';
import { PerfilService } from 'src/app/services/perfil/perfil.service';
import { PerfilModel } from 'src/app/shared/models/PerfilModel';
import { UserLoggedModel } from 'src/app/shared/models/UserLoginModel.model';


@Component({
  selector: 'app-perfil-usuario',
  templateUrl: './perfil-usuario.component.html'
})
export class PerfilUsuarioComponent implements OnInit {
  EnumEtapas = {
    Detalles: 0,
    Modifi: 1,
    Ok: 2
  };
  private etapa: Number = this.EnumEtapas.Detalles;
  dataUsuario: UserLoggedModel;
  constructor(menuService: MenuService,
    private usuarioService: AccountService,
    private perfilSevice: PerfilService,
    private alertService: AlertService) {
    menuService.tilteMenu = "Perfil de Usuario";
    menuService.btnPrevius = true;
    this.dataUsuario = usuarioService.userValue;
  }
  ngOnInit(): void {
  }
  validarEtapa(enumEtapa: number) {
    return this.etapa === enumEtapa;
  }
  onChangeEtapa(enumEtapa: number) {
    this.etapa = enumEtapa;
  }
  actualizarPerfilUsuario(perfil: PerfilModel) {
    this.perfilSevice.updatePerfil(perfil).subscribe((x) => {
      if (x["Estado"] === "Modificado") {
        this.dataUsuario.FONO_USUARIO = perfil.FONO_USUARIO;
        this.dataUsuario.firma = perfil.firma;
        this.usuarioService.saveDatos(this.dataUsuario);
        this.etapa = this.EnumEtapas.Ok;
      } else {
        this.alertService.error("Error actualizar perfil. Reintente");
      }
    }
    );
  }
}
