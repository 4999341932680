import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Location } from '@angular/common';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ScrollStrategyOptions } from '@angular/cdk/overlay';

@Component({
  selector: 'app-pedidos-pendiente-visualizarguia',
  templateUrl: './pedidos-pendiente-visualizarguia.component.html',
  styleUrls: ['./pedidos-pendiente-visualizarguia.component.css'],
})
export class PedidosPendienteVisualizarguiaComponent implements OnInit {
  tipoTexto = '';
  etapa = { sinData: false, MostrarData: false };

  @Input() rutaPdf: string =
    'https://www.polpaicoconexion.cl/intranet/facturas/pdfsn.asp?id=1167440&t=0&prod=3200';

  @ViewChild('contenidoModal') contenidoModal!: TemplateRef<any>;
  dialogRef: MatDialogRef<any>;

  @Input() isOnlyIcon = true;
  @Input() hide = false;
  @Input() openModal = false;
  @Output() modalStatusChange = new EventEmitter<boolean>();

  constructor(
    private location: Location,
    private dialog: MatDialog,
    private scrollStrategyOptions: ScrollStrategyOptions
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.openModal && changes.openModal.currentValue) {
      this.onOpenModal();
    }
  }

  goBack() {
    this.onCierrenModal();
  }

  eventoDescarPdf() {
    let a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    a.href = this.rutaPdf;
    a.download = this.tipoTexto + '.pdf';
    a.setAttribute('target', '_blank');
    a.click();
    document.body.removeChild(a);

    this.onCierrenModal();
  }

  onOpenModal() {
    this.etapa.MostrarData = true;
    this.dialogRef = this.dialog.open(this.contenidoModal, {
      maxWidth: '100%', // Puedes ajustar el ancho según tus necesidades
      disableClose: true,
      scrollStrategy: this.scrollStrategyOptions.noop(),
    });
  }

  onCierrenModal() {
    this.modalStatusChange.emit(false);
    this.dialogRef.close();
  }
}
