import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/services/auth';
import { MenuService } from 'src/app/services/menu/menu-service';
import { Constantes } from 'src/app/shared/Constantes/constantes';

@Component({
  selector: 'app-dashboardlayout',
  templateUrl: './dashboardlayout.component.html',
})
export class DashboardlayoutComponent implements OnInit {
  isMixer = false;
  isCemar = false;
  statusCss = '';

  constructor(
    public menuService: MenuService,
    private accountService: AccountService,
    private router: Router
  ) {
    if (menuService.paginaPrevius == undefined) {
      menuService.paginaPrevius = '/dashboard';
    }
    this.accountService.user.subscribe((user) => {
      if (user.CARGO == 'MIXER') this.isMixer = true;
      if (user.ROL == 'CEMAR') {
        this.isCemar=true;
      }

      this.statusCss = this.getStatus();
    });
  }
  clasModalCall = Constantes.classCierreModal;
  clasModalPerfil = Constantes.classCierreModal;
  ngOnInit(): void {
    if (this.accountService.userValue.CARGO == 'MIXER') {
      this.isMixer = true;
    }
  }

  openModalCall() {
    this.clasModalCall = Constantes.classAperturaModal;
  }
  cerrarModalCall() {
    this.clasModalCall = Constantes.classCierreModal;
  }

  openModalperfil() {
    this.clasModalPerfil = Constantes.classAperturaModal;
  }
  cerrarModalperfil() {
    this.clasModalPerfil = Constantes.classCierreModal;
  }

  goBack() {
    const currentUrl = this.router.url;
    if (currentUrl.includes('/PedidosPendientes/Detalle')) {
      this.menuService.return.next(true);
      return;
    }
    window.history.back();
  }

  isInDashboard() {
    if (window.location.pathname == '/dashboard') return true;

    return false;
  }

  getStatus() {
    if (this.accountService.userValue.ESTADO == '1') return '';
    if (this.accountService.userValue.ESTADO == '0') return 'inactive';
    return 'absent';
  }

  toDashboard() {
    this.router.navigate(['/dashboard']);
  }
}
