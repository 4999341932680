import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/services/auth/account.service';
import { MenuService } from 'src/app/services/menu/menu-service';
import { OrderService } from 'src/app/services/pedidos/pedidos.service';
import { Constantes } from 'src/app/shared/Constantes/constantes';

@Component({
  selector: 'app-modal-perfil',
  templateUrl: './modal-perfil.component.html',
  styleUrls: ['./modal-perfil.component.css'],
})
export class ModalPerfilComponent implements OnInit {
  classModalCerrarSession = Constantes.classCierreModal;
  Mixer: boolean = true;
  @Input() classModal;
  @Output() enventoCerrarModalPadre = new EventEmitter<boolean>();

  isEstadoAcordeonOpen = false;
  constructor(
    private accountService: AccountService,
    private router: Router,
    private pedidosService: OrderService, 
    private menuService:MenuService
  ) {
    this.Mixer = this.accountService.userValue.ROL == 'MIXER' ? true : false;
  }

  ngOnInit(): void {}
  eventoCerrarModal() {
    this.enventoCerrarModalPadre.emit(true);
  }
  cerrarSession() {
    this.setStatus(0);
    this.accountService.logout();
    this.router.navigate(['login']);
    this.menuService.showModalFirstTime = true;
  }
  cerrarModalSession() {
    this.classModalCerrarSession = Constantes.classCierreModal;
  }
  openModalCerrarSession() {
    this.eventoCerrarModal();
    this.classModalCerrarSession = Constantes.classAperturaModal;
  }

  toggleEstadoAcordeon() {
    this.isEstadoAcordeonOpen = !this.isEstadoAcordeonOpen;
  }

  setStatus(status: number) {
    this.pedidosService
      .setStatus(status.toString(), this.accountService.userValue.RUT_USUARIO)
      .subscribe((response) => {
        this.accountService.updateStatus(status.toString());
        console.log(response);
      });
  }
}
