<div class="dashboard">

    <div class="respuesta-perfil">
        <div class="header-page text-center pt-3 pb-3">
            <h2 class="text-center">Su perfil de usuario fue cambiado con éxito.</h2>
            <div class="number-ticket mb-5">
                <i class="icon-form-ok"></i>
            </div>
        </div>
    </div>

   

    <div class="d-flex justify-content-center editar-perfil">
        <div class="buttons mt-4 mt-md-5">
            <button class="btn btn-primary btn-cambiar-perfil btn-largo" type="button"  (click)="onChangeDetalle()">
                <i class="icon icon-back"></i>Volver 
            </button>

           
        </div>
    </div>

    

</div>