import { Component, EventEmitter, Input, Output, } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'app-modal-anular-pedido',
  templateUrl: './modal-anular-pedido.component.html',
  styleUrls: ['./modal-anular-pedido.component.css']
})
export class ModalAnularPedidoComponent {
  @Input() classModal;
  @Input() finishedProcess;
  @Input() successfulProcess;
  @Output() cerrarModalEmitter = new EventEmitter<void>();
  @Output() eliminarPedidoEmitter = new EventEmitter<void>();
  constructor(private router: Router) { }

  cerrarModal() {
    this.cerrarModalEmitter.emit();
    this.router.navigate(['/historialprogramacion']);
  }
  quitarPedido() {
    this.eliminarPedidoEmitter.emit();
  }
}
