   <!--descargar archivo-->
   <div [class]="'call-to-action ' + classModal">
    <div class="content lightbox-content-show">
        <div class="content-header"><div href="" class="icon-hide close-call icon-close"  style="cursor:pointer;" (click)="eventoCerrarModal()"  >Cerrar</div></div>
        <!--form-->
        <div class="form-download-file" *ngIf="etapa.etapaDescargar">
            <h2 class="text-center">Descargar Información</h2>

            <div class="row">
                <div class="col-12">
                  <div class="form-group" (focus)="picker.open()" (click)="picker.open()">
                    <mat-form-field appearance="fill" style=" width: 100% !important;">
                      <mat-label>Selecciona un rango de fechas</mat-label>
                      <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                        <input matStartDate formControlName="start" placeholder="Fecha desde">
                        <input matEndDate formControlName="end" placeholder="Fecha hasta">
                      </mat-date-range-input>
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-date-range-picker #picker></mat-date-range-picker>
        
                      <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">La fecha inicio
                        no es válida</mat-error>
                      <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">La fecha fin no es
                        válida</mat-error>
                    </mat-form-field>
                  </div>
                  <mat-error *ngIf="SinDatos">En la fecha solicitada, no se encontraron registros.</mat-error>
                </div>
              </div>

            <div class="buttons">
                <a  style="cursor: pointer;" type="button" class="btn btn-primary btn-download-file" (click)="enventoDescargarItems()">Descargar</a>
            </div>
        </div>
        <!--end div form--> 

        <div class="response" *ngIf="etapa.etapaOk">
            <h2 class="text-center">La información de Guía fue descargada con éxito.</h2>
            <p class="text-center"></p>

            <div class="number-ticket">
                <i class="icon-form-ok"></i>
            </div>
        </div>
        <!--end responde-->
        
    </div>
</div>
