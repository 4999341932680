import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChange,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MenuService } from 'src/app/services/menu/menu-service';
import { Constantes } from 'src/app/shared/Constantes/constantes';
import { ComboType } from 'src/app/shared/models/ComboType';
import { FiltroHistorialPedidoModel } from 'src/app/shared/models/FiltroHistorialPedidoModel';
import * as moment from 'moment';

@Component({
  selector: 'app-historial-programacion-filtros',
  templateUrl: './historial-programacion-filtros.component.html',
  styleUrls: ['./historial-programacion-filtros.component.css'],
})
export class HistorialProgramacionFiltrosComponent implements OnInit {
  @Input() ClaseModal = Constantes.classCierreModal;
  @Input() selectObrasText: string = '';
  @Input() selectObras: string = '0';
  estadoPedido: number = -1;
  @Input() lstObras: ComboType[];
  @Input() isWeekFilter: boolean = true;
  @Output() eventofiltrar: EventEmitter<any> = new EventEmitter();
  @Output() downloadAsPDF: EventEmitter<any> = new EventEmitter();
  @Input() Opcion: boolean;
  formEdicion: FormGroup;
  firstTime = true;

  obrasArray: ComboType[];
  @ViewChild('InputSearch', { static: true }) nameSearchElement: ElementRef;

  constructor(private menu: MenuService, nameSearchElement: ElementRef) {
    this.nameSearchElement = nameSearchElement;

    this.formEdicion = new FormGroup({
      numeroBuscar: new FormControl(),
      start: new FormControl(),
    });
  }

  ngOnInit(): void {
    let dataFiltro = this.menu.filterFields;
    if (dataFiltro) {
      this.firstTime = false;
      if (dataFiltro.searchText)
        this.formEdicion.get('numeroBuscar').setValue(dataFiltro.searchText);
      if (dataFiltro.fechaInicio)
        this.formEdicion.get('start').setValue(dataFiltro.fechaInicio);
      if (dataFiltro.selectedEstado) {
        this.estadoSelected(dataFiltro.selectedEstado);
        this.estadoPedido = dataFiltro.selectedEstado;
      }
    }
    this.obrasArray = this.getObrasNotEmpty();
  }

  ngOnChanges(simplesChanges: SimpleChange) {
    if (
      simplesChanges['lstObras'] &&
      simplesChanges['lstObras'].currentValue != undefined
    ) {
      this.obrasArray = this.getObrasNotEmpty();
    }
  }

  getObrasNotEmpty() {
    return this.lstObras.filter((x) => x.isEmpty == false);
  }

  reLoadObrasFilter() {
    //TODO: Revisar el -1
    if (this.lstObras.length > -1) {
      this.obrasArray = this.lstObras;
    } else {
      setTimeout(() => {
        this.reLoadObrasFilter();
      }, 100);
    }
  }

  eventoCerrarModal() {
    if (!this.firstTime) this.ClaseModal = Constantes.classCierreModal;
  }
  eventoAbrirModal() {
    this.ClaseModal = Constantes.classAperturaModal;
  }

  estadoSelected(element: number) {
    return this.estadoPedido === element;
  }

  onFiltrar() {
    this.firstTime = false;
    this.nameSearchElement.nativeElement.value = '';
    this.obrasArray = this.getObrasNotEmpty();
    let RangoFechas = this.obtenerFechaInicioYFin(this.formEdicion.value.start);
    let dataFiltro: FiltroHistorialPedidoModel = {};
    dataFiltro.selectedObra = this.selectObras;
    dataFiltro.searchText = this.formEdicion.value.numeroBuscar;
    console.log('isWeekFilter', this.isWeekFilter);
    if (this.isWeekFilter) {
      dataFiltro.fechaInicio = RangoFechas.fechaInicio;
      dataFiltro.fechaFin = RangoFechas.fechaFin;
    } else {
      dataFiltro.fechaInicio = this.formEdicion.value.start;
      dataFiltro.fechaFin = this.formEdicion.value.start;
    }

    dataFiltro.selectedEstado = this.estadoPedido;
    if (this.lstObras) {
      let obraText = this.lstObras.find((x) => x.id === this.selectObras);
      if (obraText) {
        this.selectObrasText = obraText?.value;
        this.menu.codigoproductoSelecionado = this.selectObras;
        this.menu.productoSelecionado = this.selectObrasText;
      }
    }
    console.log('dataFiltro', dataFiltro);
    this.eventofiltrar.emit(dataFiltro);
    this.eventoCerrarModal();
  }

  onKey(value) {
    this.obrasArray = [];
    this.selectSearch(value);
  }
  selectSearch(value: string) {
    let filter = value.toLowerCase();
    const temp = this.getObrasNotEmpty();
    for (let i = 0; i < temp.length; i++) {
      let option = temp[i];
      if (
        option.value.toLowerCase().indexOf(filter) >= 0 ||
        option.id.toLowerCase().indexOf(filter) >= 0
      ) {
        this.obrasArray.push(option);
      }
    }
  }

  DescargarPage() {
    this.downloadAsPDF.emit();
  }

  obtenerFechaInicioYFin(fecha: Date) {
    let fechaPrincipal = moment(fecha);

    // Obtener la fecha del lunes
    let fechaInicio = fechaPrincipal.clone().startOf('isoWeek');

    // Obtener la fecha del sábado
    let fechaFin = fechaPrincipal.clone().endOf('isoWeek').subtract(1, 'days');

    return { fechaInicio, fechaFin };
  }

  isSelectObrasValid() {
    return this.selectObras !== '0';
  }
}
