<div class="chat-container" *ngIf="visibleChat">
  <button class="chat-toggle" (click)="toggleChat()">
    <mat-icon>chat</mat-icon>
    <span *ngIf="unReadMessagesCount > 0" class="unread-messages">{{
      unReadMessagesCount
    }}</span>
  </button>
  <div class="chat-window" *ngIf="showChat">
    <button class="close-btn" (click)="toggleChat()"></button>
    <div class="messages-container" #messagesContainer>
      <div
        *ngFor="let message of messages; let isFirst = first"
        class="message"
        [ngClass]="{
          received: message.sender !== 'Yo',
          sent: message.sender === 'Yo'
        }"
        [ngStyle]="isFirst ? { 'border-radius': '10px 0 0 0' } : {}"
      >
        <p class="content">{{ message.content }}</p>
        <span class="sender">{{ message.sender }}</span>
        <span class="timestamp">{{ message.timestamp }}</span>
      </div>
    </div>

    <form (submit)="sendMessage()">
      <input
        type="text"
        class="form-input mat-input form-control"
        [(ngModel)]="newMessage"
        name="newMessage"
        placeholder="Mensaje..."
      />
      <button
        mat-icon-button
        color="primary"
        type="submit"
        [disabled]="newMessage.length == 0"
      >
        <mat-icon [ngStyle]="{ color: '#007bff' }">send</mat-icon>
      </button>
    </form>
  </div>
</div>
