   <!--descargar archivo-->
   <div [class]="'call-to-action ' + classModal">
    <div class="content lightbox-content-show">
        <div class="content-header"><div href="" class="icon-hide close-call icon-close"  style="cursor:pointer;" (click)="eventoCerrarModal()"  >Cerrar</div></div>
        <!--form-->
        <div class="form-download-file" *ngIf="etapa.etapaDescargar">
            <h2 class="text-center">Descargar Información</h2>
            <p class="text-center">¿Deseas descargar la información de sus saldos disponibles en un archivo .XLS?</p>
            <div class="buttons">
                <button type="button" class="btn btn-primary btn-download-file" (click)="enventoExportarDataExcel()">Descargar</button>
            </div>
        </div>
        <!--end div form-->

        <div class="response" *ngIf="etapa.etapaOk">
            <h2 class="text-center">Descargado con éxito</h2>
            <p class="text-center">{{mensaje}}</p>

            <div class="number-ticket">
                <i class="icon-form-ok"></i>
            </div>
        </div>
        <!--end responde-->
        
    </div>
</div>