import { Component, Input, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { EncuestaService } from 'src/app/services/Encuesta/encuesta.service';
import { AccountService } from 'src/app/services/auth';

interface Category {
  name: string;
  selected: boolean;
  Origen: string;
}

@Component({
  selector: 'app-encuesta-satisfacion',
  templateUrl: './encuesta-satisfacion.component.html',
  styleUrls: ['./encuesta-satisfacion.component.css'],
})
export class EncuestaSatisfacionComponent implements OnInit {
  @Input() Origen_Apartado;
  @Input() Pregunta;
  @Input() ShowModalEncuesta;
  @Input() ObraFiltrada;
  @Input() NombreObraFiltrada;
  IsVisible = false;
  IsCompleteEncuesta = false;
  IsMovil = false;

  IsProgramacion = false;
  constructor(
    private deviceService: DeviceDetectorService,
    private encuestaService: EncuestaService,
    private accountService: AccountService
  ) {}

  ngOnInit(): void {
    this.IsMovil = this.deviceService.isMobile();
    this.categories = this.categories.filter(
      (w) => w.Origen == this.Origen_Apartado
    );
    this.IsProgramacion = this.Origen_Apartado == 'PROGRAMACION' ? true : false;
    this.showModal = this.ShowModalEncuesta;
  }

  selectedStars: number = 0;
  hoveredStars: number;
  categories: Category[] = [
    {
      name: 'Poca facilidad contacto',
      selected: false,
      Origen: 'PROGRAMACION',
    },
    { name: 'Poca flexibilidad', selected: false, Origen: 'PROGRAMACION' },
    {
      name: 'No atención personalizada',
      selected: false,
      Origen: 'PROGRAMACION',
    },
    { name: 'Canales poco ágiles', selected: false, Origen: 'PROGRAMACION' },
    { name: 'Política no clara', selected: false, Origen: 'PROGRAMACION' },
    { name: 'No capacidad ante urgencias', selected: false, Origen: 'RUTAS' },
    {
      name: 'Sin notificaciones ante atrasos',
      selected: false,
      Origen: 'RUTAS',
    },
    { name: 'No cumplimiento de entrega', selected: false, Origen: 'RUTAS' },
    { name: 'Política no clara', selected: false, Origen: 'RUTAS' },
    {
      name: 'Mala coordinación en la planificación',
      selected: false,
      Origen: 'RUTAS',
    },
    { name: 'Mala atención mixer', selected: false, Origen: 'RUTAS' },
    { name: 'Asesoría no adecuada', selected: false, Origen: 'CERTIFICADOS' },
    { name: 'Poca visita en obra', selected: false, Origen: 'CERTIFICADOS' },
    {
      name: 'Poca rapidez en la solución de problemas',
      selected: false,
      Origen: 'CERTIFICADOS',
    },
  ];
  comment: string = '';

  selectStars(stars: number) {
    this.selectedStars = stars;

    if (this.selectedStars > 0) {
      this.IsProgramacion = false;
      this.IsVisible = true;
    }
  }

  toggleCategory(category: Category) {
    debugger;
    category.selected = !category.selected;
  }

  sendFeedback() {
    console.log('sendFeedback');
    let Comentarios = '';
    this.IsCompleteEncuesta = true;
    if (this.selectedStars < 4) {
      let CategoriaTemp = this.categories.filter((x) => x.selected == true);
      Comentarios = CategoriaTemp.map((item) => item.name).join(', ');
    }

    let data = {
      pregunta: this.Pregunta,
      puntos: this.selectedStars.toString(),
      categorias: Comentarios,
      comentario: this.comment,
      apartado: this.Origen_Apartado,
      CodObra: this.ObraFiltrada == undefined ? '' : this.ObraFiltrada,
      NombreObra:
        this.NombreObraFiltrada == undefined ? '' : this.NombreObraFiltrada,
    };

    this.encuestaService.saveOldSurvey(data).subscribe((res) => {
      let result = res;
      setTimeout(() => {
        this.closeModal();
      }, 2000);
    });
  }

  showModal: boolean = false;

  openModal() {
    this.showModal = true;
  }

  closeModal() {
    this.showModal = false;
  }
}
