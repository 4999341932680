<app-loader></app-loader>

<router-outlet></router-outlet>
<app-chat  Origen_Apartado="RUTAS"
  
Pregunta="De acuerdo al último despacho, con que nota calificaría nuestro servicio de entrega"
>
</app-chat>


