<div class="smaller-container" *ngIf="!IsComplet">
  <app-alert></app-alert>
  <div class="box row">
    <form>
      <div class="form-group row">
        <div class="col-12">
          <label for="" class="text-title" style="font-size: 18px"
            >Firmar guía</label
          >
        </div>
        <div class="row col-xl-12">
          <div
            *ngIf="havePreloadSignature()"
            class="col-6 col-sm-6 d-flex align-items-center justify-content-between"
          >
            <span class="new-receptor-label">Firma precargada</span>
            <div class="custom-switch">
              <input
                type="checkbox"
                id="toggleSignature"
                (change)="togglePreloadSignature()"
              />
              <label for="toggleSignature" class="slider"></label>
            </div>
          </div>
          <div
            class="col-6 col-sm-6 d-flex align-items-center justify-content-between"
          >
            <span class="new-receptor-label">Nuevo receptor</span>
            <div class="custom-switch" *ngIf="!this.isAnonimo">
              <input 
                type="checkbox"
                id="toggleSwitch"
                (change)="isChecked = !isChecked"
              />
              <label for="toggleSwitch" class="slider"></label>
            </div>
          </div>
        </div>
        <div class="col-12"><br /></div>
        <div *ngIf="isChecked" class="col-xl-12 p-0">
          <div class="col-xl-12">
            <input
              class="form-input mat-input form-control"
              [type]="'text'"
              name="NombreRecptor"
              [(ngModel)]="pedido.MX_CLI_FIRMA_NOMBRE"
              placeholder="Ingresa tu nombre"
              [required]="isAnonimo"
              (input)="trimLeadingWhitespaceAndUpdateModel($event, nameReceptor)"
              #nameReceptor="ngModel"
            />
            <label class="mat-label label-input" for="Obs">Nombre</label>
          </div>
          <div class="col-12"><br /></div>
          <div class="col-xl-12">
            <input
              class="form-input mat-input form-control"
              [type]="'text'"
              name="RutReceptor"
              [(ngModel)]="pedido.MX_CLI_FIRMA_RUT"
              placeholder="Ingresa tu RUT"
              [required]="isAnonimo"
              (input)="trimLeadingWhitespaceAndUpdateModel($event, rutReceptor)"
              #rutReceptor="ngModel"
            />
            <label class="mat-label label-input" for="Obs">Rut</label>
          </div>
        </div>
        <div class="firma-container col-xl-12">
          <div class="firma-box">
            <div class="firma-content">
              <canvas
                class="canvasSignature"
                #signatureCanvas
                width="580"
                height="120"
                (mousedown)="startDrawing($event)"
                (mouseup)="stopDrawing()"
                (mousemove)="draw($event)"
                (ontouchstart)="startDrawing($event)"
                (ontouchend)="stopDrawing()"
                (ontouchmove)="draw($event)"
              ></canvas>
            </div>
            <div class="firma-actions">
              <input
                type="file"
                #fileInput
                (change)="onFileSelected($event)"
                accept=".jpg,.jpeg,.png"
                style="display: none"
              />
              <input
                type="file"
                #fileInputCamera
                (change)="onFileSelected($event)"
                accept="image/*"
                capture="camera"
                style="display: none"
              />
              <div
                class="col-12"
                (click)="clearSignature()"
                style="cursor: pointer"
              >
                <div class="row">
                  <div class="col-icon-img">
                    <img
                      src="assets/images/icons/recargarfirma.svg"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex mt-4">
            <div
              class="col-icon-img icon_advertencia_azul icon-signature"
            ></div>
            <div>
              <label class="text-firma"
                >Esta firma también será considerada en el caso de adición de
                agua y/u otros.</label
              >
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <button
    type="button "
    class="btn btn-primary col-12 col-sm-2 my-3 ml-auto"
    [ngClass]="{ disabled: !isValidData() }"
    [disabled]="!isValidData()"
    (click)="guardar()"
  >
    Aceptar
  </button>
</div>

<div *ngIf="IsComplet" class="contenedor-final">
  <div class="box smaller-container">
    <div class="header-container-modal"></div>

    <div class="col-10 col-sm-12 mx-auto px-0" style="text-align: center">
      <img
        [src]="'assets/images/icons/Check-modal.svg'"
        class="img-fluid style-warning mb-2 mb-sm-0"
        alt=""
      /><br />
      <h6 class="text-title-modal">
        La información de la guía fue guardada con éxito
      </h6>
      <p class="text-descripcion-modal">
        En unos instantes podrás visualizar tu guía firmada.
      </p>
    </div>
  </div>

  <app-encuesta-guia
    Origen_Apartado="GUIAS"
    [ObraFiltrada]="pedido.CODIGO_PROYE_H"
    [NombreObraFiltrada]="pedido.NOMBRE_PROYECTO_H"
    [NroGuia]="pedido.FOLIO_H"
    (surveySubmittedSuccessEvent)="showModalSurveySuccess()"
    Pregunta="Para nosotros es muy importante tu opinión. Te pedimos que por favor, evalúes estos 3 puntos en base al último despacho."

    *ngIf="!isAnonimo"
  >
  </app-encuesta-guia>
</div>

<ng-template #modalContentSurveySuccess>
  <div class="dashboard container" style="padding: 0 1rem">
    <div class="d-flex justify-content-end">
      <button
        mat-icon-button
        class="close-icon close-button"
        (click)="closeModal()"
      >
        <mat-icon aria-label="Cerrar">close</mat-icon>
      </button>
    </div>
    <div class="header-container title-container">
      <mat-icon [ngStyle]="{ color: '#007a47' }" style="transform: scale(2)"
        >check_circle_outline</mat-icon
      >
      <h6 class="text-title title mt-3">Evaluación enviada con éxito</h6>
    </div>
    <div class="mx-0 mx-sm-4">
      <p style="text-align: center">
        Agradecemos tu evaluación. Así nos ayudarás a mejorar.
      </p>
    </div>

    <div class="buttons d-flex justify-content-center">
      <a
        href="javascript:void(0)"
        class="btn btn-primary btn-back btn-l-md"
        style="max-width: 250px !important"
        (click)="closeModal()"
        >Ir al inicio</a
      >
    </div>
  </div>
</ng-template>
