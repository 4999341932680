<div class="container reject-container">
  <div class="box">
    <label class="text-title">Motivos de rechazo</label>
    <div class="box-reject">
      <div class="left-container-reject">
        <div class="items">
          <div *ngFor="let rejectReason of rejectReasonsSelected" class="item">
            <span>
              {{ rejectReason.description }}
            </span>
            <mat-icon class="delete-icon" (click)="removeReason(rejectReason)"
              >delete</mat-icon
            >
          </div>
        </div>
        <div class="select-container">
          <div class="select-header" (click)="openSelect()">
            <span class="">Seleccionar motivos de rechazo</span>
            <mat-icon *ngIf="!isSelectOpen" style="color: #0033a1"
              >expand_more</mat-icon
            >
            <mat-icon *ngIf="isSelectOpen" style="color: #0033a1"
              >expand_less</mat-icon
            >
          </div>
          <div *ngIf="isSelectOpen" class="items-select">
            <div
              *ngFor="let rejectReason of rejectReasons"
              class="item-select custom-checkbox"
            >
              <input
                class="checkbox"
                type="checkbox"
                [value]="rejectReason.id"
                [id]="rejectReason.id"
                [checked]="isSelected(rejectReason)"
              />
              <span
                class="checkmark"
                (click)="clickReason(rejectReason)"
              ></span>

              <span class="check-label capitalize">
                {{ rejectReason.description }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="rigth-container-reject">
        <div>
          <div class="col-icon-img col-xl-12 row">
            <div>
              <input
                type="file"
                #fileInput
                (change)="onFileSelected($event)"
                multiple
                accept=".jpg,.jpeg,.png"
                style="display: none"
              />
              <br />
              <div class="image-list" style="margin-left: 30px">
                <div *ngFor="let image of images" class="image-item">
                  <img
                    [src]="image.preview"
                    alt="{{ image.file.name }}"
                    width="50"
                    height="50"
                  />
                  <button (click)="removeImage(image)" class="btn btn-remove">X</button>
                </div>
              </div>
            </div>
            <div class="col-12"></div>
            <div class="img-information">
              <div
                class="mb-2 mb-md-0 d-flex align-items-md-center ml-md-4 icon-obs"
              >
                <img
                  src="assets/images/icons/Advertencia-Imagen.svg"
                  class="img-fluid"
                  alt=""
                />
                <div class="col-10 text-imagen-obs">
                  <span
                    >Recuerda que solo puedes subir imágenes.</span
                  >
                </div>
              </div>
              <div class="attach-btn">
                <button
                  type="button"
                  class="btn btn-primary-foto"
                  (click)="openFileSelector()"
                >
                  Adjuntar foto
                </button>
              </div>
            </div>
            <!--<img
          src="assets/images/icons/Advertencia-Imagen.svg"
          class="img-fluid"
          alt=""
        />
        <div class="col-11 text-advertencia">
          <span
            >Puedes adjuntar fotos o videos con un tamaño máximo de 16 MB.</span
          >
        </div>-->
          </div>
        </div>
        <div class="form-group row" style="margin-top: 20px">
          <div class="col-12">
            <label class="" for="Retorno">Observaciones de rechazo</label>
            <textarea
              id="Retorno"
              class="form-control"
              name="Retorno"
              value=""
              style="border-color: #0033a1"
              [formControl]="rejectForm.controls.rejectObservations"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="buttons-container-reject mt-md-4">
    <br />
    <button
      type="button"
      class="btn btn-primary-volver custom-btn"
      (click)="goBack()"
    >
      Volver
    </button>
    <br />
    <button
      type="button"
      class="btn btn-primary custom-btn ml-md-4"
      [ngClass]="{ disabled: isDisabled() }"
      [disabled]="isDisabled()"
      (click)="confirmModal()"
    >
      Enviar
    </button>
  </div>
</div>

<ng-template #modalContent>
  <div class="dashboard container" style="padding-left: 1rem">
    <button mat-icon-button class="close-icon close-button">
      <mat-icon aria-label="Cerrar" (click)="closeModal()">close</mat-icon>
    </button>
    <div *ngIf="!isConfirm">
      <div class="header-container title-container">
        <mat-icon [ngStyle]="{ color: '#f1ae2f' }" style="transform: scale(1.4)"
          >warning</mat-icon
        >
        <h6 class="text-title title">
          ¿Deseas confirmar el motivo del rechazo?
        </h6>
      </div>
      <div>
        <p style="text-align: center">
          Una vez confirmada no será posible cambiar la información.
        </p>
      </div>
      <div style="display: flex">
        <div class="buttons" style="width: 47.5%; margin-right: auto">
          <a
            href="javascript:void(0)"
            class="btn back-button"
            (click)="closeModal()"
            >Seguir editando</a
          >
        </div>

        <div class="buttons" style="width: 47.5%">
          <a
            href="javascript:void(0)"
            class="btn btn-primary btn-back btn-l-md"
            (click)="submit()"
            >Confirmar</a
          >
        </div>
      </div>
    </div>
    <div *ngIf="isConfirm">
      <div class="header-container title-container">
        <mat-icon [ngStyle]="{ color: '#007a47' }" style="transform: scale(1.4)"
          >check_circle_outline</mat-icon
        >
        <h6 class="text-title title">Rechazo enviado con éxito</h6>
      </div>
      <div>
        <p style="text-align: center">Esta acción no se puede deshacer</p>
      </div>

      <div class="buttons">
        <a
          href="javascript:void(0)"
          class="btn btn-primary btn-back btn-l-md"
          (click)="ok()"
          >Aceptar</a
        >
      </div>
    </div>
  </div>
</ng-template>
