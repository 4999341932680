<div>
  <div
    class="notification"
    [ngClass]="{ hideNotificationCss: !isNotificationVisible }"
    *ngIf="order.neg !='3100'"
  >
    <div class="green-line"></div>
    <mat-icon class="notification-icon">check</mat-icon>
    <span class="notification-text">Guía de despacho enviada al cliente.</span>
    <mat-icon class="close-icon" (click)="hideNotification()">close</mat-icon>
  </div>

  <div style="text-align: center" >
    <qrcode [qrdata]="linkQr" class="qr-code">
      <div class="qrcode">
        <canvas></canvas>
      </div>
    </qrcode>
  </div>

  <div class="text-title">
    <label>Firmar guía</label>
  </div>

  <div class="texto-simple" (click)="withoutInternet()" *ngIf="order.neg !='3400' && order.neg !='3100'">
    <span>¿Sin conexión? <a style="cursor: pointer">Continúa aquí</a></span>
  </div>
  <div class="d-md-flex justify-content-md-center qr-buttons mt-md-4">
    <div class="space-button">
      <button type="button" class="btn btn-primary-volver" (click)="goBack()">
        Volver
      </button>
    </div>

    <div class="space-button ml-md-4">
      <button
        type="button"
        class="btn btn-primary-nofirma"
        (click)="onOpenModal()"
      >
        Guía no firmada
      </button>
    </div>
  </div>
</div>

<div class="margin-lateral-modal">
  <ng-template #contenidoModal>
    <div class="dashboard container" style="padding: 1rem; padding-bottom: 0px">
      <button mat-icon-button class="close-icon close-button">
        <mat-icon aria-label="Cerrar" (click)="onCierrenModal()"
          >close</mat-icon
        >
      </button>

      <div style="text-align: center">
        <mat-icon
          *ngIf="PasoModal.preguntaModal"
          [ngStyle]="{ color: '#f1ae2f' }"
          style="transform: scale(1.4)"
          >warning</mat-icon
        >
        <mat-icon
          *ngIf="!PasoModal.preguntaModal"
          [ngStyle]="{ color: '#007a47' }"
          style="transform: scale(1.4)"
          >check_circle_outline</mat-icon
        >
        <br />
        <h6 class="text-title-modal">{{ tituloModal }}</h6>
        <p class="text-descripcion-modal">{{ descripcionModal }}</p>
      </div>
      <div style="display: flex" *ngIf="PasoModal.preguntaModal">
        <div class="buttons" style="width: 47.5%; margin-right: auto">
          <a
            href="javascript:void(0)"
            class="btn back-button"
            (click)="onCierrenModal()"
            >Seguir editando</a
          >
        </div>
        <div class="buttons" style="width: 47.5%">
          <a
            href="javascript:void(0)"
            class="btn btn-primary btn-back btn-l-md"
            (click)="confirmarGuiaNoFirmada()"
            >Confirmar</a
          >
        </div>
      </div>
      <div *ngIf="!PasoModal.preguntaModal">
        <button
          type="button"
          class="btn btn-primary"
          (click)="onCierrenModal()"
        >
          Aceptar
        </button>
      </div>
    </div>
  </ng-template>
</div>
