export class Constantes {
  static MaxPerOrder: number = 350;
  static classCierreModal: string = 'call-to-action';
  static classAperturaModal: string = 'call-to-action-show';
  static regularExpresionClave = /^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,16}$/g;
  static numeroReintentos: number = 3;
  static frencuenciaProgramacion = "0"; // Todo: 0 cuando t_carga es igual a cant_produc
  static servicios = [
    { id: '60002620', value: 'MUESTRAS DE HORMIGON CADA 75 m3' },
    { id: '60002621', value: 'MUESTRAS DE HORMIGON CADA 100 m3' },
    { id: '60002622', value: 'MUESTRAS DE HORMIGON CADA 150 m3' },
    {
      id: '60002640,60002651,60000110,60005834',
      value: 'MUESTREO HORARIO ESPECIAL',
    },
    { id: '60002652', value: 'SERVICIO BOMBEO HORARIO ESPECIAL' },
    { id: '60000076', value: 'MUESTRAS HORMIGON' },
    { id: '60003206,60006051', value: 'MUESTRAS DE HORMIGON CADA 80 M3' },
    { id: '60002823', value: 'SERVICIO BOMBEO INTEGRAL' },
    { id: '60003820', value: 'MUESTRA INTERNA LABORATORIO' },
    { id: '60000260', value: 'MUESTRA ADICIONAL SOLICITADA POR CLIENTE' },
    { id: '60003020', value: 'SERVICIO BOMBEO CON TORRE DISTRIBUCION' },
    { id: '60003020', value: 'MUESTRAS DE HORMIGON CADA 85 m3' },
    { id: '60006041', value: 'MUESTRAS DE HORMIGON FLEXO C/150 M3' },
    { id: '10058128', value: 'ADITIVO HIDROFUGO SIKA 100' },
    { id: '60005818', value: 'SERCICIO DE BOMBEO TELESCOPICO MIN 30' },
    { id: '60000018', value: 'SERV. DE TRANSPORTE COMPARTIDO ' },
    { id: '60000019', value: 'SERV. DE TRANSPORTE COMPLETO ' },
    { id: '60000025', value: 'ACTIVO FIJO ' },
    { id: '60000030', value: 'DIFERENCIA DE PRECIO ' },
    { id: '60000033', value: 'INTERESES PRESTAMOS PRESENTE MES ' },
    { id: '60000034', value: 'RECUPERACION GASTOS ' },
    { id: '60000050', value: 'ACEITE QUEMADO ' },
    { id: '60000051', value: 'ACTIVO FIJO GRUPO MINETTI ' },
    { id: '60000054', value: 'ARRIENDO CARGADOR FRONTAL ' },
    { id: '60000056', value: 'ARRIENDO MIXER ' },
    { id: '60000058', value: 'BENCINA SIN PLOMO ' },
    { id: '60000060', value: 'CARGA INCOMPLETA ' },
    { id: '60000061', value: 'CARGO FIJO ' },
    { id: '60000063', value: 'DESCUENTO PRONTO PAGO ' },
    { id: '60000064', value: 'DIFERENCIA COBRADA DE MENOS ' },
    { id: '60000065', value: 'DIFERENCIA PRECIO ' },
    { id: '60000066', value: 'ENSAYO DE MATERIALES ' },
    { id: '60000068', value: 'SERVICIO DE APERTURA PLANTA ' },
    { id: '60000069', value: 'RECARGO DESPACHO ENTRE 21 Y 24 HRS ' },
    { id: '60000070', value: 'FAENAS NOCTURNAS ' },
    { id: '60000072', value: 'FUNDAS CUBRE CANOA EN PVC. ' },
    { id: '60000074', value: 'INTERESES ' },
    { id: '60000078', value: 'PETROLEO DIESEL ' },
    { id: '60000080', value: 'SERV COMPLEMENTO JORNADA ELECTRICO ' },
    { id: '60000081', value: 'SERV COMPLEMENTO JORNADA ESTACIONARIO ' },
    { id: '60000082', value: 'SERV COMPLEMENTO JORNADA TELESCOPICO 28 ' },
    { id: '60000083', value: 'SERV COMPLEMENTO JORNADA TELESCOPICO 36 ' },
    { id: '60000084', value: 'SERVICIO ARRIENDO TRANSPORTE ' },
    { id: '60000086', value: 'SERVICIO BOMBEO ESTACIONARIO ' },
    { id: '60000087', value: 'SERVICIO BOMBEO ADICIONAL ' },
    { id: '60000088', value: 'SERVICIO BOMBEO TELESCOPICO ' },
    { id: '60000089', value: 'SERVICIO BOMBEO TELESCOPICO 36 MIN 25 ' },
    { id: '60000090', value: 'SERVICIO DE CINTA ' },
    { id: '60000091', value: 'SERVICIO JORNADA BOMBEO ADICIONAL ' },
    { id: '60000092', value: 'SERVICIO MANO DE OBRA ENERO 2000 ' },
    { id: '60000093', value: 'SOBRE ESTADIAS ' },
    { id: '60000094', value: 'SOBRE DISTANCIA ' },
    { id: '60000099', value: 'SERVICIO GENERICO CON GUIA ' },
    { id: '60000120', value: 'MUESTRAS HORMIGON SOLICITADA POR CLIENTE ' },
    { id: '60000130', value: 'SOBRE ESTADIAS DELVOCRETE NOCHE (12) ' },
    { id: '60000131', value: 'SOBRE ESTADIAS DELVOCRETE DÍA (6) ' },
    { id: '60000181', value: 'REGULARIZACION M3 DE HORMIGON ' },
    { id: '60000253', value: 'CARGA INCOMPLETA MIN 5,0 ' },
    { id: '60000254', value: 'CARGA INCOMPLETA MIN 5,5 ' },
    { id: '60000255', value: 'CARGA INCOMPLETA MIN 6,0 ' },
    { id: '60000256', value: 'CARGA INCOMPLETA MIN 6,5 ' },
    { id: '60000290', value: 'CHATARRA ' },
    { id: '60000331', value: 'SALIDA EN FALSO POR SERVICIO DE BOMBEO ' },
    { id: '60000611', value: 'DIFERENCIA PRECIO SERV. BOMBEO ' },
    { id: '60000671', value: 'VENTA DE HIELO PLANTA HORMIGON ' },
    { id: '60000847', value: 'SERVICIO APERTURA PLANTA DIA FERIADO ' },
    { id: '60000950', value: 'SERVICIO EXPRESS BETONSA ' },
    { id: '60001521', value: 'FAENA ESPECIAL POR TUBERIA ADICIONAL SER ' },
    { id: '60001530', value: 'SERVICIO BOMBEO TELESCOPICO 43 MIN 25 ' },
    { id: '60001540', value: 'SERVICIO BOMBEO TELESCOPICO 32 MIN 25 ' },
    { id: '60001541', value: 'SERV COMPLEMENTO JORNADA TELESCOPICO 32 ' },
    { id: '60001550', value: 'TUBERIA ADIC. SERV. BOMBEO POR ML ' },
    { id: '60001560', value: 'SERV COMPLEMENTO JORNADA TELESCOPICO 43 ' },
    { id: '60001580', value: 'INCORPORACION DE PIGMENTO ' },
    { id: '60001840', value: 'CARGA INCOMPLETA MIN 4,0 ' },
    { id: '60002540', value: 'SERVICIO DEVOLUCION DE PRODUCTO ' },
    { id: '60002602', value: 'HORAS EXTRAS PLANTA ' },
    { id: '60002603', value: 'HORAS EXTRAS NOCTURNO MIXER ' },
    { id: '60002604', value: 'SERVICIO TUBERIA VERTICAL FIJA EN OBRA ' },
    { id: '60002641', value: 'ANULACION DE PROGRAMA DE HORMIGON ' },
    { id: '60002642', value: 'RECURSO ADIC PARA CUBRIR DEMANDA VOLUMEN ' },
    { id: '60002650', value: 'JORNADA EXTRAORDINARIA ' },
    { id: '60002760', value: 'DIFERENCIA PRECIO ' },
    { id: '60002820', value: 'DESPACHO FUERA DE HORARIO LABORAL ' },
    { id: '60002852', value: 'CARGA INCOMPLETA MIN 7,0 ' },
    { id: '60002953', value: 'MUESTRAS DE HORMIGON CADA 50 m3 ' },
    { id: '60003030', value: 'MUESTRAS DE HORMIGON CADA 85 m3 ' },
    { id: '60003360', value: 'PROBETA ADICIONAL SOLICITADA POR CLIENTE ' },
    { id: '60003612', value: 'GASTOS GENERALES / UTILIDAD ' },
    { id: '60005130', value: 'SERV FABRICACION Y ACTIVACION PREDOSIFIC ' },
    { id: '60005213', value: 'HORMIGONES PETREOS ' },
    { id: '60005713', value: 'FIJO PLANTA CON FUNCIONAMIENTO (DIARIO) ' },
    { id: '60005714', value: 'FIJO SERVICIO BOMBA TITULAR (MENSUAL) ' },
    { id: '60005715', value: 'FIJO PLANTA SIN FUNCIONAMIENTO (DIARIO) ' },
    { id: '60005717', value: 'FIJO SERVICIO BOMBA STAND BY (MENSUAL) ' },
    { id: '60005718', value: 'MOVILIZACION Y DESMOVILIZACION DE BOMBA ' },
    { id: '60005800', value: 'ARRIENDO DE EQUIPOS Y MAQUINARIA EN DÃA ' },
    { id: '60005801', value: 'ARRIENDO DE EQUIPOS Y MAQUINARIA EN DÃA ' },
    { id: '60005803', value: 'COBROS FIJOS MENSUALES CON GUIA ' },
    { id: '60005804', value: 'DESMONTAJE DE PLANTA (S/G) ' },
    { id: '60005805', value: 'ENTREGA NO RECEPCIONADA(FLETE FALSO) ' },
    { id: '60005808', value: 'MONTAJE DE PLANTA (S/G) ' },
    { id: '60005809', value: 'SERVICIO BOMBEO ESTACIONARIO MIN 30 ' },
    { id: '60005810', value: 'SERVICIO BOMBEO ESTACIONARIO MIN 35 ' },
    { id: '60005811', value: 'SERVICIO BOMBEO TELESCOPICO 28 MIN 30 ' },
    { id: '60005812', value: 'SERVICIO BOMBEO TELESCOPICO 28 MIN 35 ' },
    { id: '60005813', value: 'SERVICIO BOMBEO TELESCOPICO 32 MIN 30 ' },
    { id: '60005814', value: 'SERVICIO BOMBEO TELESCOPICO 32 MIN 35 ' },
    { id: '60005815', value: 'SERVICIO BOMBEO TELESCOPICO 36 MIN 30 ' },
    { id: '60005816', value: 'SERVICIO BOMBEO TELESCOPICO 36 MIN 35 ' },
    { id: '60005817', value: 'SERVICIO BOMBEO TELESCOPICO 43 MIN 30 ' },
    { id: '60005820', value: 'SERVICIO OPERACION NOCTURNO ' },
    { id: '60005822', value: 'TRANSPORTE PERSONAL EN FAENA ' },
    { id: '60005825', value: 'SERVICIO BOMBEO ESTACIONARIO MIN 25 ' },
    { id: '60005826', value: 'SERVICIO BOMBEO ESTACIONARIO MIN 50 ' },
    { id: '60005827', value: 'SERVICIO BOMBEO ESTACIONARIO MIN 500 ' },
    { id: '60005828', value: 'SERVICIO BOMBEO TELESCOPICO 28 MIN 25 ' },
    { id: '60005829', value: 'SERVICIO BOMBEO TELESCOPICO 28 MIN 50 ' },
    { id: '60005830', value: 'SERVICIO BOMBEO TELESCOPICO 28 MIN 500 ' },
    { id: '60005831', value: 'SERVICIO BOMBEO TELESCOPICO 36 ' },
    { id: '60005835', value: 'RECURSO ADIC PARA CUBRIR DEMANDA VOLUMEN ' },
    { id: '60005836', value: 'TRASLADO DE BOMBA ' },
    { id: '60005837', value: 'SERVICIO BOMBEO ESTACIONARIO MIN 40 M3 ' },
    { id: '60005838', value: 'SERVICIO BOMBEO TELESCOPICO MIN 25 ' },
    { id: '60005839', value: 'SERVICIO BOMBEO TELESCOPICO MIN 30 ' },
    { id: '60005840', value: 'SERVICIO BOMBEO TELESCOPICO MIN 35 ' },
    { id: '60005841', value: 'SERVICIO BOMBEO TELESCOPICO MIN 40 ' },
    { id: '60006001', value: 'SERVICIO DEVOLUCION DE PRODUCTO ' },
    { id: '60006012', value: 'COBROS FIJOS MENSUALES SIN GUIA ' },
    { id: '60006013', value: 'FIJO PLANTA SIN FUNCIONAMIENTO (D) S/GUI ' },
    { id: '60006014', value: 'SERVICIO GENERICO SIN GUIA ' },
    { id: '60006015', value: 'JORNADA EXTRAORDINARIA SIN GUIA ' },
    { id: '60006016', value: 'BONO DE EXCELENCIA ' },
    { id: '60006032', value: 'ARRIENDO MIXER S/G ' },
    { id: '60006033', value: 'SERVICIO BOMBEO ESTACIONARIO RM ' },
    { id: '60006035', value: 'SERVICIO BOMBEO TELESCOPICO RM ' },
    { id: '60006036', value: 'SERVICIO EQUIPO SHOCRETE MIN 30 M3 ' },
    { id: '60006037', value: 'SERV COMPL JORNADA SHOCRETE MIN 30 M3 ' },
    { id: '60006038', value: 'PERSONAL DE BOMBEO ' },
    { id: '60006042', value: 'SERVICIO BOMBEO TELESCOPICO MIN 32 ' },
    { id: '60006043', value: 'SERVICIO OPERACION NOCTURNO S/GUIA ' },
    { id: '60006062', value: 'REGULARIZACIÃ“N SERV COMPLEMENTO BOMBEO ' },
    { id: '60006064', value: 'SERVICIO BOMBEO RM TELESCOPICO 40 MTS ' },
    { id: '60006066', value: 'SERV COMPL JORNADA RM TEL 40MT MIN 50 M3 ' },
    { id: '60006071', value: 'PERSONAL DE BOMBEO S/GUIA ' },
    { id: '60006073', value: 'PERSONAL DE BOMBEO S/GUIA ' },
    { id: '60006074', value: 'AJUSTE DE BOMBEO MINIMO S/GUIA ' },
    { id: '60006083', value: 'HORMIGONES PRUEBA - ENSAYO PROBETAS ' },
    { id: '60006084', value: 'HORMIGONES PRUEBA - ENSAYO PERMEABILIDAD ' },
    { id: '60006091', value: 'FIJO POR 24/7 + 4 EQ ' },
    { id: '60006092', value: 'FIJO POR 2 EQ MIXER ' },
    { id: '60006094', value: 'FIJO POR 24/7 +4 EQ ' },
    { id: '60006095', value: 'FIJO POR 2 EQ MIXER ' },
    { id: '60006101', value: 'SERVICIO DE ARMADO DE TUBERIA ' },
  ];
  static Idservicios = [
    //muestras
    '60002620',
    '60002621',
    '60002622',
    '60002640',
    '60002651',
    '60000076',
    '60003206',
    '60003820',
    '60002953',
    '60000110',
    '60000260',
    '60003030',
    '60005834',
    '60006051',
    '60006041',
    //aditivos
    '10058128',
    //bombeos
    '60002652',
    '60002823',
    '60003020',
    '60006033',
    '60006035',
    '60005810',
    '60000088',
    '60000086',
    '60001550',
    '60005815',
    '60005811',
    '60005818',
    '60000018',
    '60000019',
    '60000025',
    '60000030',
    '60000033',
    '60000034',
    '60000050',
    '60000051',
    '60000054',
    '60000056',
    '60000058',
    '60000060',
    '60000061',
    '60000063',
    '60000064',
    '60000065',
    '60000066',
    '60000068',
    '60000069',
    '60000070',
    '60000072',
    '60000074',
    '60000078',
    '60000080',
    '60000081',
    '60000082',
    '60000083',
    '60000084',
    '60000086',
    '60000087',
    '60000088',
    '60000089',
    '60000090',
    '60000091',
    '60000092',
    '60000093',
    '60000094',
    '60000099',
    '60000120',
    '60000130',
    '60000131',
    '60000181',
    '60000253',
    '60000254',
    '60000255',
    '60000256',
    '60000290',
    '60000331',
    '60000611',
    '60000671',
    '60000847',
    '60000950',
    '60001521',
    '60001530',
    '60001540',
    '60001541',
    '60001550',
    '60001560',
    '60001580',
    '60001840',
    '60002540',
    '60002602',
    '60002603',
    '60002604',
    '60002641',
    '60002642',
    '60002650',
    '60002760',
    '60002820',
    '60002852',
    '60002953',
    '60003030',
    '60003360',
    '60003612',
    '60005130',
    '60005213',
    '60005713',
    '60005714',
    '60005715',
    '60005717',
    '60005718',
    '60005800',
    '60005801',
    '60005803',
    '60005804',
    '60005805',
    '60005808',
    '60005809',
    '60005810',
    '60005811',
    '60005812',
    '60005813',
    '60005814',
    '60005815',
    '60005816',
    '60005817',
    '60005820',
    '60005822',
    '60005825',
    '60005826',
    '60005827',
    '60005828',
    '60005829',
    '60005830',
    '60005831',
    '60005835',
    '60005836',
    '60005837',
    '60005838',
    '60005839',
    '60005840',
    '60005841',
    '60006001',
    '60006012',
    '60006013',
    '60006014',
    '60006015',
    '60006016',
    '60006032',
    '60006033',
    '60006035',
    '60006036',
    '60006037',
    '60006038',
    '60006042',
    '60006043',
    '60006062',
    '60006064',
    '60006066',
    '60006071',
    '60006073',
    '60006074',
    '60006083',
    '60006084',
    '60006091',
    '60006092',
    '60006094',
    '60006095',
    '60006101',
  ];
  //pendiente por respuesta 11000875
  static IdserviciosRemover = [
    '60000611',
    '11000875',
    '60002540',
    '60002602',
    '60002603',
    '60002604',
    '60002641',
    '60002642',
    '60002650',
    '60000056',
    '60001521',
    '60000033',
    '60000064',
    '60000065',
    '60000034',
    '60000030',
    '60001840',
    '60001560',
    '60000063',
    '60002760',
    '60002820',
    '60003612',
    '60000060',
    '60000061',
    '60000066',
    '60000068',
    '60000069',
    '60000070',
    '60000081',
    '60000082',
    '60000083',
    '60000087',
    '60000090',
    '60000091',
    '60000093',
    '60000094',
    '60000099',
    '60000130',
    '60000131',
    '60000181',
    '60000253',
    '60000254',
    '60000255',
    '60000256',
    '60000331',
    '60000671',
    '60000950',
    '60003360',
    '60000847',
    '60005130',
    '60000018',
    '60000019',
    '60005805',
    '60005836',
    '60006016',
    '60006015',
    '60006013',
    '60006012',
    '60005803',
    '60006014',
    '60005800',
    '60005801',
    '60005822',
    '60006043',
    '60006032',
    '60005820',
    '60006036',
    '60006037',
    '60006038',
    '60005808',
    '60006062',
    '60002852',
    '60006066',
    '60006071',
    '60006073',
    '60005835',
    '60006074',
    '60006001',
    '60002620',
    '60002621',
    '60002953',
    '60002622',
    '60006041',
    '60006051'

  ];

  static hormigonTipos = ['10', '17', '21', '25', '30', '35', '40', '99'];

  static aditivosTipos = ['118', '118A', 'FCO'];
  static aditivosCodigos = ['10058128'];

  static bombeosTipos = ['S01'];
  static bombeoCodigos = [
    '60002652',
    '60002823',
    '60003020',
    '60006033',
    '60006035',
    '60005810',
    '60000088',
    '60000086',
    '60001550',
    '60005815',
    '60005811',
    '60005818',
    '60000086',
    '60000087',
    '60000088',
    '60000089',
    '60000091',
    '60000331',
    '60000611',
    '60001530',
    '60001540',
    '60001550',
    '60005809',
    '60005810',
    '60005811',
    '60005812',
    '60005813',
    '60005814',
    '60005815',
    '60005816',
    '60005817',
    '60005825',
    '60005826',
    '60005827',
    '60005828',
    '60005829',
    '60005830',
    '60005831',
    '60005837',
    '60005838',
    '60005839',
    '60005840',
    '60005841',
    '60006033',
    '60006035',
    '60006038',
    '60006042',
    '60006062',
    '60006064',
    '60006071',
    '60006073',
    '60006074',
  ];

  static muestrasTipos = ['S02'];
  static muestrasCodigos = [
    '60002620',
    '60002621',
    '60002622',
    '60002640',
    '60002651',
    '60000076',
    '60003206',
    '60003820',
    '60002953',
    '60000110',
    '60000260',
    '60003030',
    '60005834',
    '60006051',
    '60006041',
  ];

  static mesesCortos = [
    'Ene',
    'Feb',
    'Mar',
    'Abr',
    'May',
    'Jun',
    'jul',
    'Ago',
    'Sep',
    'Oct',
    'Nov',
    'Dic',
  ];

  static HolidayDates = [
    new Date("2022/01/01"),
    new Date("2022/04/15"),
    new Date("2022/04/16"),
    new Date("2022/05/21"),
    new Date("2022/06/21"),
    new Date("2022/06/27"),
    new Date("2022/07/16"),
    new Date("2022/08/15"),
    new Date("2022/09/16"),
    new Date("2022/09/19"),
    new Date("2022/10/10"),
    new Date("2022/10/31"),
    new Date("2022/11/01"),
    new Date("2022/12/08"),
    new Date("2023/01/01"),
    new Date("2023/01/02"),
    new Date("2023/04/07"),
    new Date("2023/04/08"),
    new Date("2023/05/01"),
    new Date("2023/05/21"),
    new Date("2023/06/26"),
    new Date("2023/07/16"),
    new Date("2023/08/15"),
    new Date("2023/09/18"),
    new Date("2023/09/19"),
    new Date("2023/10/09"),
    new Date("2023/10/27"),
    new Date("2023/11/01"),
    new Date("2023/12/08"),
    new Date("2023/12/25"),

  ];

}
