import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MenuService } from 'src/app/services/menu/menu-service';
import { Location } from '@angular/common';
import { Utilidades } from 'src/app/shared/FuntionsGlobales/Utilidades';
import { PerfilModel } from 'src/app/shared/models/PerfilModel';
import { UserLoggedModel } from 'src/app/shared/models/UserLoginModel.model';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AccountService } from 'src/app/services/auth';

@Component({
  selector: 'app-perfil-usuario-modifi',
  templateUrl: './perfil-usuario-modifi.component.html',
  styleUrls: ['./perfil-usuario-modifi.component.css'],
})
export class PerfilUsuarioModifiComponent implements OnInit {
  formEdicion: FormGroup;
  isMobile: boolean;
  isTablet: boolean;
  user_validation_messages = {
    telfono: [
      { type: 'required', message: 'Teléfono es requerido' },
      { type: 'minlength', message: 'Teléfono debe contener 9 dígitos' },
      { type: 'maxlength', message: 'Teléfono debe contener 9 dígitos' },
    ],
  };
  @Input() dataUsuario: UserLoggedModel;
  @Input() numberEtapas: any;
  @Output() enventoAcualizarData = new EventEmitter<PerfilModel>();
  @Output() eventoPadreCambiarEtapa = new EventEmitter<number>();

  NewUpdate: boolean = false;
  constructor(
    private menu: MenuService,
    private location: Location,
    private router: Router,
    private deviceService: DeviceDetectorService,
    private accountService: AccountService
  ) {}

  get telefono() {
    return this.formEdicion.get('telefono');
  }

  @ViewChild('signatureCanvas') signatureCanvas: ElementRef<HTMLCanvasElement>;
  private context: CanvasRenderingContext2D;
  private drawing = false;
  Mixer: boolean;

  ngOnInit(): void {
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    let campos = {
      nombre: this.dataUsuario.NOMBRE_USUARIO,
      rut: this.dataUsuario.RUT_USUARIO,
      nombreEmpresa: this.dataUsuario.NOMBRE_EMPRESA,
      rutEmpresa: this.dataUsuario.RUT_EMPRESA,
      email: this.dataUsuario.MAIL_USUARIO,
      telefono: this.dataUsuario.FONO_USUARIO.replace(/ /g, ''),
      firma: this.dataUsuario.firma,
    };
    this.Mixer = this.accountService.userValue.ROL == 'MIXER' ? true : false;
    this.formEdicion = new FormGroup({
      telefono: new FormControl(
        campos.telefono,
        Validators.compose([
          Validators.required,
          Validators.minLength(9),
          Validators.maxLength(9),
        ])
      ),
      nombre: new FormControl({ value: campos.nombre, disabled: true }),
      rut: new FormControl({ value: campos.rut, disabled: true }),
      nombreEmpresa: new FormControl({
        value: campos.nombreEmpresa,
        disabled: true,
      }),
      rutEmpresa: new FormControl({ value: campos.rutEmpresa, disabled: true }),
      email: new FormControl({ value: campos.email, disabled: true }),
      firma: new FormControl({ value: campos.firma, disabled: true }),
    });
  }

  ngAfterViewInit() {
    if (!this.Mixer) {
      this.context = this.signatureCanvas.nativeElement.getContext('2d');
      if (this.isMobile) {
        this.context.canvas.width = 300;
        this.context.canvas.height = 150;
      }
      if (this.isMobile || this.isTablet) this.touchStart();
      this.getDefaultSignature();
    }
  }

  getDefaultSignature() {
    if (!this.dataUsuario.firma) return;
    this.context.clearRect(
      0,
      0,
      this.signatureCanvas.nativeElement.width,
      this.signatureCanvas.nativeElement.height
    );
    const image = new Image();
    image.onload = () => {
      this.context.drawImage(image, 0, 0);
    };
    image.src = this.dataUsuario.firma;
  }

  touchStart() {
    this.signatureCanvas.nativeElement.ontouchstart = (event) => {
      event.preventDefault();
      this.NewUpdate = true;
      this.drawing = true;
      this.context.beginPath();
      this.context.moveTo(this.getTouchX(event), this.getTouchY(event));
    };
    this.signatureCanvas.nativeElement.ontouchmove = (event) => {
      event.preventDefault();
      if (!this.drawing) return;
      this.context.lineTo(this.getTouchX(event), this.getTouchY(event));
      this.context.stroke();
    };
    this.signatureCanvas.nativeElement.ontouchend = (event) => {
      event.preventDefault();
      this.drawing = false;
      this.formEdicion.controls.firma.setValue(this.getSignatureAsBase64());
    };
  }

  getTouchX(event: TouchEvent): number {
    return (
      event.touches[0].clientX -
      this.signatureCanvas.nativeElement.getBoundingClientRect().left
    );
  }

  getTouchY(event: TouchEvent): number {
    return (
      event.touches[0].clientY -
      this.signatureCanvas.nativeElement.getBoundingClientRect().top -
      10
    );
  }

  getSignatureAsBase64(): string {
    return this.signatureCanvas.nativeElement.toDataURL('image/png');
  }

  onSubmit() {
    // debugger;
    if (!this.formEdicion.invalid) {
      let perfil: PerfilModel = new PerfilModel();
      perfil.FONO_USUARIO = this.telefono.value;
      perfil.Key = this.dataUsuario.KEY;
      perfil.MAIL_USUARIO = this.dataUsuario.MAIL_USUARIO;
      perfil.NOMBRE_USUARIO = this.dataUsuario.NOMBRE_USUARIO;
      perfil.RUT = this.dataUsuario.RUT_USUARIO;
      perfil.opemail1 = this.dataUsuario.opemail1;
      perfil.opemail2 = this.dataUsuario.opemail2;
      perfil.opemail3 = this.dataUsuario.opemail3;
      perfil.opemail4 = this.dataUsuario.opemail4;
      perfil.opsms = this.dataUsuario.opsms;
      perfil.firma = this.formEdicion.controls.firma.value;
      this.enventoAcualizarData.emit(perfil);
    } else {
      return;
    }
  }
  soloNumero(event) {
    return Utilidades.soloNumero(event);
  }

  goBack() {
    this.eventoPadreCambiarEtapa.emit(this.numberEtapas.Detalles);
  }

  clearSignature() {
    this.context.clearRect(
      0,
      0,
      this.signatureCanvas.nativeElement.width,
      this.signatureCanvas.nativeElement.height
    );
  }

  startDrawing(event: MouseEvent) {
    this.NewUpdate = true;
    this.drawing = true;
    this.context.beginPath();
    this.context.moveTo(this.getMouseX(event), this.getMouseY(event));
  }

  draw(event: MouseEvent) {
    if (!this.drawing) return;
    this.context.lineTo(this.getMouseX(event), this.getMouseY(event));
    this.context.stroke();
  }

  private getMouseX(event: MouseEvent): number {
    return (
      event.clientX -
      this.signatureCanvas.nativeElement.getBoundingClientRect().left
    );
  }

  private getMouseY(event: MouseEvent): number {
    return (
      event.clientY -
      this.signatureCanvas.nativeElement.getBoundingClientRect().top
    );
  }

  stopDrawing() {
    this.drawing = false;
    this.formEdicion.controls.firma.setValue(this.getSignatureAsBase64());
  }

  Modificar() {
    this.NewUpdate = true;
  }
}
